import React, { Component } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Modal,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import { AutoComplete } from 'antd';

import { CSVLink, CSVDownload } from 'react-csv';
import DocumentTitle from "react-document-title";
import swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Menu } from "antd";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import moment from "moment";
import "sweetalert2/dist/sweetalert2.min.css";
import AdminDeclareModal from "./AdminDeclareModal";

import SingleOpportunityFeedbackModal from "./SingleOpportunityFeedbackModal";
import DeclareOffsetHoursModal from "../../MyVolunteerHistory/DeclareOffsetHoursModal";

import SingleOpportunityEmployeeModal from "./SingleOpportunityEmployeeModal";
import SingleOpportunityExternalParticipantsModal from './SingleOpportunityExternalParticipantsModal';
import SingleOpportunityExternalParticipantsEditModal from './singleOpportunityExternalParticipantsEditModal';
import { data } from "jquery";
import FamilyCertificateModal  from './FamilyCertificateGeneration';
import SingleOpportunityFamilyFriendModal from "./SingleOpportunityFamilyFriendModal";
import { OrgDataContext } from "../../../../../routes";
import configs from "../../../../../config/urlConfigs";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../../../actions/RoleAction";
import { generatePreSignedGetUrl } from "../../../../../s3PresignedUrls";
const readCookie = require("../../../../../cookie.js").readCookie;
import { Upload } from 'react-feather';
import UploadCSVModal from "./UploadCSVModal";
import { search } from "react-icons-kit/fa/search.js";


let organisationDataContext = null;
 class SingleOpportunityParticipantsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      selectedCity: '',
      citiesToDisplay: [],
      citySearchQuery: '',

      loading: true,
      selectedOpportunity: this.props.selectedOpportunity || null,
      attendeeData: this.props.selectedOpportunity.attendees,
      tableData: this.props.selectedOpportunity,
      // tableData: null,
      showAdminDeclareModal: false,
      attendeeId: null,
      editingRowIndex: null,
      showFeedbackModal: false,
      showEmployeeEditModal: false,
      familyFriendsModal: false,
      friendsList: [],
      guestList: [],
      totalEmployeeHours: this.props.totalEmployeeHours,
      totalEmloyeeParticipation: 0,
      // totalGuestHours: '00:00',
      // totalEmployeeHours: '00:00',
      totalGuestHours: this.props.totalGuestHours,
      totalGuestParticipation: 0,
      offices:[],
      office :'',
      departments: [],
      department: '',
      mergeData :[],
      employeeAttendanceOverride: this.props.selectedOpportunity.employeeAttendanceOverride,
      ShowFriendsCertificateModal:false,
      allowExternalParticipants :false,
      enableExternalParticipants:props.enableExternalParticipants,
      showExternalParticipantsModal:false,
      externalParticipantsData :[],
      showExternalParticipantsEditModal :false,
      showOffsetTimeModal: false,
      attendeeNumbers: 0,
      attendeeVolunteers:0,
      guestVolunteer :0,
     path : props.path,
     showUploadCSVModal:false,
     filteredlist:[],
     edit:false,
     searchText:'',
     checkBoxList:  this.props.selectedOpportunity.attendees.map((id) => ({
      id: id.employeeId,
      checked: false,
    })),
    multipleAttendenceApprove:false,
     selectAllList:false,
     showFriendsName:false,
     markAttendenceWithDecalredAttendance:false,
    };
    this.editButton = this.editButton.bind(this);
    this.showOffsetTimeModal = this.showOffsetTimeModal.bind(this);
    this.hideOffsetTimeModal = this.hideOffsetTimeModal.bind(this);
    this.feedback = this.feedback.bind(this);
    this.employeeEditButton = this.employeeEditButton.bind(this);
    this.viewCertificate = this.viewCertificate.bind(this);
    this.deleteParticipant = this.deleteParticipant.bind(this);
    // this.show = this.show.bind(this);
    this.familyFriendsModal = this.familyFriendsModal.bind(this);
    this.familyFriendsNameModal = this.familyFriendsNameModal.bind(this);
    this.familyParticipationTime = this.familyParticipationTime.bind(this);
    // this.fetchFriendsList = this.fetchFriendsList.bind(this);
    this.roundMinutes = this.roundMinutes.bind(this);
    this.editExternalParticipants = this.editExternalParticipants.bind(this);
    this.getExternalParticipantsList = this.getExternalParticipantsList.bind(this)
    // this.fetchIBLCities = this.fetchIBLCities.bind(this);
    this.viewExternalParticipantsCertificate = this.viewExternalParticipantsCertificate.bind(this);
    this.removeExternalParticipant = this.removeExternalParticipant.bind(this);
    this.handleSearch=this.handleSearch.bind(this)
    this.toggleChecked=this.toggleChecked.bind(this)
    
  }

   
   showOffsetTimeModal() {
     this.setState({ showOffsetTimeModal: true });
   }
   hideOffsetTimeModal() {
     this.setState({ showOffsetTimeModal: false });
   }

  
   componentDidMount() {
    //  this.fetchIBLCities();
    // let path = this.props.path;
    this.getExternalParticipantsList();
  //  this.getAllData();
   

  }


   getAllDataOld() {
     fetch(
       configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + this.state.path + "/analytics",
       {
         method: "GET",
         headers: {
           Auth: JSON.parse(readCookie("access_token")).access_token,
         },
       }
     ).then((data) => data.json())
       .then(
         function (data) {
           let attendeeData = [];
           if (data.status === "ok") {
            //  let employeeHours = 0;
             this.setState({ selectedOpportunity: data.selectedOpportunity });
             // fetch(
             //   configs.BACKEND_API_URL + "/ee/volunteer-opportunity/" + data.selectedOpportunity.customUrl,
             //   {
             //     method: "GET",
             //     headers: {
             //       Auth: JSON.parse(readCookie("access_token")).access_token,
             //     },
             //   }
             // )
             //   .then((data) => data.json())
             //   .then(
             //     function (data) {
             //       if (data.status === "ok") {
             // this.setState({ totalGuestHours: data.tableData[0].guestHours })
             // this.setState({ totalEmployeeHours: data.tableData[0].employeeHours })
             {
               $(() => {
                 // use UI arrows in page content to control scrolling
                 this.scrolling = false;
                 // var that = this;

                 $('#drag-table-left').mouseover((evt) => {
                   this.scrolling = true;
                   this.startScrolling($('table.table'), 15, evt.target.id, 'right');
                 });

                 $('#drag-table-right').mouseover((evt) => {
                   this.scrolling = true;
                   this.startScrolling($('table.table'), 15, evt.target.id, 'left');
                 });

                 $('#drag-table-left').mouseout(() => { this.scrolling = false; });
                 $('#drag-table-right').mouseout(() => { this.scrolling = false; });
               });
               // this.setState({ employeeAttendanceOverride : data.data.employeeAttendanceOverride});
               var sumTime = 0;
               var attendeeNumbers = 0;
              //  var attendeeVolunteer = 0;


               if (this.props.selectedOpportunity.offsetHours && this.props.selectedOpportunity.offsetHours.employees) {
                 sumTime = moment.duration(`${this.props.selectedOpportunity.offsetHours.employees.hours}:00`).asMinutes();
                 attendeeNumbers = attendeeNumbers + (this.props.selectedOpportunity.offsetHours.employees.count || 0);
              
              
                }

              //  this.state.attendeeData.map((attendeeObj) => {
              //    attendeeVolunteer++;
              //    if (attendeeObj.hasOwnProperty('updatedParticipatedAttendence') && attendeeObj.updatedParticipatedAttendence === true) {
              //      attendeeNumbers++;
              //    }
              //    else if (attendeeObj.updatedParticipatedAttendence === '' && attendeeObj.participated === true) {
              //      attendeeNumbers++;
              //    }
              //    if (attendeeObj.updatedParticipatedAttendence === false || attendeeObj.participated === false) {
              //      return
              //    }
              //    else if (attendeeObj.updatedParticipatedAttendence === true) {
              //      sumTime += moment.duration(attendeeObj.updatedHoursTime || attendeeObj.hoursClocked).asMinutes();
              //    }
              //    else if (attendeeObj.updatedParticipatedAttendence === '' && attendeeObj.participated === true) {
              //      sumTime += moment.duration(attendeeObj.updatedHoursTime || attendeeObj.hoursClocked).asMinutes();
              //    }
              //  });
             }
            //  let h = sumTime / 60,
            //    m = sumTime % 60;
            //  if (String(m).length < 2) m = `0${m}`;
            //  if (String(h).length < 2) h = `0${h}`;
             // var totalHours = moment().hours(h).minutes(m).format("HH:mm");
            //  var totalHours = `${h}:${m}`
             // data.data.attendees.sort(function(x, y) {
             //   if((x.adminName === "-" ) < (y.adminName )) return -1;
             //   if((x.adminName === "-" ) > (y.adminName )) return 1;
             //   return 0;  
             //   });
            //  employeeHours = totalHours;
             // this.setState({ totalEmployeeHours: totalHours });
             // this.setState({ attendeeData: data.data.attendees });
             // this.setState({ tableData: data.data });
            //  this.setState({ attendeeNumbers: attendeeNumbers });
            //  this.setState({ attendeeVolunteers: attendeeVolunteer });
             // attendeeData = data.data.attendees;

            //  this.fetchFriends(this.props.selectedOpportunity.id);
             //     }
             // this.setState({ loading: false });
             //   }.bind(this)
             // )
             // .catch(() => {
             //   this.setState({ loading: false });
             // });


           } else {
             this.gotoOverviewPage();
           }
         }.bind(this)
       );
   }

    getExternalParticipantsList(){
      fetch(
        configs.BACKEND_API_URL + '/ee/external-participants/' + this.props.selectedOpportunity.id ,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Auth': JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify(data),
        })
        .then(function (response) {
          return response.json();
        }).then(function (data) {
          {
            $(() => {
              // use UI arrows in page content to control scrolling
              this.scrolling = false;
              // var that = this;
        
              $('#drag-table-left').mouseover((evt) => {
                this.scrolling = true;
                this.startScrolling($('table.table'), 15, evt.target.id, 'right');
              });
        
              $('#drag-table-right').mouseover((evt) => {
                this.scrolling = true;
                this.startScrolling($('table.table'), 15, evt.target.id, 'left');
              });
        
              $('#drag-table-left').mouseout(() => { this.scrolling = false; });
              $('#drag-table-right').mouseout(() => { this.scrolling = false; });
            });
          }
          this.setState({ externalParticipantsData :data.userData });
        }.bind(this));  
    }


  gotoOverviewPage() {
    window.location.pathname =
      window.location.pathname.split("/participants")[0];
  }

  gotoAnalyticsPage() {
    window.location.pathname =
      window.location.pathname.split("/participants")[0] + "/analytics";
  }
  gotoGalleryPage() {
    window.location.pathname =
      window.location.pathname.split("/participants")[0] + "/gallery";
  }

  hideDeclareModal(row) {
    this.setState({ showAdminDeclareModal: false, attendeeId: null });
  }

  hideUploadCSVModal(){
    this.setState({ showUploadCSVModal:false});
  }
  showTimeModal() {
    this.setState({ showEmployeeTimeModal: true });
  }
  declaredAttendance(cell, row) {
    if (row.participated === true) {
      return <div>Attendance Declared</div>;
    } else if (
      row.participated === false &&
      row.updatedParticipatedAttendence === true
    ) {
      return <div>Attendance Declared</div>;
    } else if (
      row.participated === "" &&
      row.updatedParticipatedAttendence === true
    ) {
      return <div>Attendance Declared</div>;
    } else if (
      row.participated === "" &&
      row.updatedParticipatedAttendence === false
    ) {
      return <div>Missed</div>;
    } else if (row.participated === false && row.declared === true) {
      return <div>Missed</div>;
    } else if (row.participated === "") {
      return <div>Pending Declaration</div>;
    }
  }

  adminDeclaredAttendance(cell, row) {
    if (row["updatedParticipatedAttendence"] === true) {
      return <div>Approved</div>;
    } else if (row["updatedParticipatedAttendence"] === false) {
      return <div>Declined</div>;
      // }
    } else if (
      (row["participated"] === true || row["participated"] === false) &&
      row["adminName"] === "-"
    ) {
      return <div>Pending Approval</div>;
    } else if (row["participated"] === "") {
      return null;
    }
  }
  showDeclareModal(attendeeDetails, attendeeIndex) {
    this.setState({
      showAdminDeclareModal: true,
      attendeeId: attendeeDetails,
      editingRowIndex: attendeeIndex,
    });
  }
  editEmployeebutton(cell, row, _, index) {
    if (
      JSON.parse(readCookie("userData")).role === "admin" 
      // (row.listId && row.id)
    ) {
      return (
        <Button
          className="btn-sq btn-edit"
          onClick={this.showDeclareModal.bind(this, row, index)}
        >
          Edit
        </Button>
      );
    } else if (this.state.selectedParticipant === row.id) {
      return (
        <Button
          type="submit"
          class="btn-sq btn-success save"
          value="save"
          id="save"
        >
          Save Changes
        </Button>
      );
    }
  }

  show(attendeeDetails, attendeeIndex) {
    this.setState({
      familyFriendsModal: true,
      editingRowIndex: attendeeIndex,
      attendeeId: attendeeDetails,
    });
  }

  getHours(cell, row) {
    if (row['participated'] === true) {
      return (
        row["startTime"] +
        " - " +
        row["endTime"] +
        " (" +
        row["hoursClocked"] +
        " hours)"
      );
    }
    else if (
      row["updatedParticipatedAttendence"] === true &&
      row["updatedStartTime"] !== "" &&
      row["updatedEndTime"] !== ""
    ) {
      return (
        row["updatedStartTime"] +
        " - " +
        row["updatedEndTime"] +
        " (" +
        row['updatedHoursTime'] +
        " hours)"
      );
    } else if (
      row["updatedParticipatedAttendence"] === true &&
      row["updatedStartTime"] === "" &&
      row["updatedEndTime"] === ""
    ) {
      return (
        row["startTime"] +
        " - " +
        row["endTime"] +
        "(" +
        row["hoursClocked"] +
        " hours)"
      );
    } else {
      return "-";
    }
  }
  editButton(cell, row, _, index) {
    if (
      this.props.role === "admin" 
      // || (row.listId && row.id)
    ) {
      return (
        <Button
          className="btn-sq btn-edit"
          onClick={this.showDeclareModal.bind(this, row, index)}
        >
          Approve/Unapprove/Update
        </Button>
      );
    } else if (this.state.selectedParticipant === row.id) {
      return (
        <Button
          type="submit"
          class="btn-sq btn-success save"
          value="save"
          id="save"
        >
          Save Changes
        </Button>
      );
    }
  }

  checkName(cell, row,props) {
    if (cell) {
      if (props.role === "admin") {
        return (
          <a href={"/profile/" + row.id} target="_blank">
            {cell}
          </a>
        );
      } else {
        return (
          <a href={"/profile/" + row.id} target="_blank">
            {cell}
          </a>
        );
      }
    }
     else return "N/A";
  }

  checkbox(cell,row,props){
    const foundObject = props[0].find((obj) => obj.id == cell);

    let check=(props[1] || foundObject?.checked)
    return (
      <div>
        <input style={{cursor:'pointer'}} type="checkbox" className="" 
           checked={ check}  
           onChange={(e) =>props[2](cell)}  
            />
      </div>
    )
  }
  approveAttendence(declaredOne=false){

    if(!(this.state.selectAllList ||this.state.checkBoxList.some((checkbox) => checkbox.checked === true))){
      swal("No Participants Selected", "You have not selected any participants for approval. Please select at least one participant to approve attendance.");
      return
    }
    console.log(declaredOne==true?true:false);
   this.setState({
    multipleAttendenceApprove:true,
    showAdminDeclareModal: true,
      attendeeId: this.state.attendeeData[0],
      editingRowIndex: 0,
      markAttendenceWithDecalredAttendance:declaredOne==true?true:false
   })
    
  }

  aftersubmitdeclareModel(){
    // console.log('aklsdasdas  ');
    const checkList=this.props.selectedOpportunity.attendees.map((id) => ({
      id: id.employeeId,
      checked: false,
    }))
    this.setState({
      checkBoxList:checkList,
      selectAllList:false,
      multipleAttendenceApprove:false,
      markAttendenceWithDecalredAttendance:false
     })
     window.location.reload();
  }

  adminName(cell, row,props) {
    if (cell) {
      if (props.role ==="admin") {
        return (
          <a href={"/profile/" + row.adminId} target="_blank">
            {cell}
          </a>
        );
      } else {
        return (
          <a href={"/profile/" + row.adminId} target="_blank">
            {cell}
          </a>
        );
      }
    } else return "";
  }
  feedback(cell, row, _, index) {
    if (row.experience !== "" && row.participated === true) {
      return (
        <a onClick={this.showFeedbackModal.bind(this, row, index)}>
          Experience
        </a>
      );
    } else {
      return "";
    }
  }
  selectedBatch(cell, row, _, index) {
    // console.log(row);
    if (row.selectedGroup !== "" && row.selectedGroup !==undefined) {
      return (
        <p>
          {row.selectedGroup}
        </p>
      );
    } else {
      return "-";
    }
  }
  selectedTransportRoute(cell, row, _, index) {
    if (row.selectedTransportedRoute !== "" && row.selectedTransportedRoute !==undefined && row?.transportRequired=='Yes') {
      return (
        <p>
          {row.selectedTransportedRoute}
        </p>
      );
    } else {
      return "-";
    }
  }
  hideFeedbackModal() {
    this.setState({ showFeedbackModal: false });
  }
  showFeedbackModal(attendeeDetails, attendeeIndex) {
    this.setState({
      showFeedbackModal: true,
      attendeeId: attendeeDetails,
      editingRowIndex: attendeeIndex,
    });
  }

  employeeEditButton(cell, row, _, index) {
    if (
      (this.props.role ==="user") &&
      this.state.tableData.isUserAttendee === true &&
      this.state.tableData.status === "Completed" &&
      row.id === JSON.parse(readCookie("userData")).id && 
       row['updatedParticipatedAttendence'] === ''
      //  &&
      // row["updatedStartTime"] === ""
    ) {
      return (
        <Button
          className="btn-sq btn-edit"
          onClick={this.showEmployeeEditModal.bind(this, row, index)}
        >
          Update
        </Button>
      );
    } else {
      return "-"

    } 
  }

  showFamilyFriendsCertificateModal(attendeeDetails, attendeeIndex){
    this.setState({
        ShowFriendsCertificateModal:true,
        attendeeId: attendeeDetails,
        editingRowIndex: attendeeIndex,
      });
  }

  hideFamilyFriendsCertificateModal(){
    this.setState({ ShowFriendsCertificateModal:false })
  }

  getCertificate(id) {
    let voId = this.state.selectedOpportunity.id;
    // let voId = this.state.selectedOpportunity
    fetch(
      configs.BACKEND_API_URL +
      "/ee/volunteer-opportunity/get-certificates/" +
      voId +
      "/" +
      id,
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then((data) => data.json())
      .then((data)=> {
         var a = document.createElement('a');
          a.href = data.pdfUrl;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
        }
      );
  }
  viewCertificate(cell, row, _, index) {
    if(this.props.enableCertificateGeneration){
      let num = 0
      const data = this.props.friendsList
      ? this.props.friendsList.map((data) => {
        
        if (data.volunteerId === row.id) {
         data.friends.map((data)=>{
          if(data.attended){
             num++
               }
         })
          // return data.friendsCount;
        }
      })
      : null;
      if ((this.props.role === 'admin') && 
      (row['updatedParticipatedAttendence'] === true)) {
        return (
          <a>
            <Button onClick={this.showFamilyFriendsCertificateModal.bind(this, row, index)} >{num > 0 ? 
            `View ${num +1} Certificates` : `View ${num +1} Certificate`  }</Button>
          </a>
        );
      }
      else if (
          !this.state.employeeAttendanceOverride &&
        row["participated"] === true &&
        JSON.parse(readCookie("userData")).id === row.id
      ) {
        return (
          <a>
            <Button onClick={this.showFamilyFriendsCertificateModal.bind(this, row, index)}>{num > 0 ? 
            `View ${num +1} Certificates` : `View ${num +1} Certificate`}</Button>
          </a>
        );
      } else if (
          this.state.employeeAttendanceOverride &&
        row["updatedParticipatedAttendence"] === true &&
        JSON.parse(readCookie("userData")).id === row.id
      ) {
        return (
          <a  onClick={this.showFamilyFriendsCertificateModal.bind(this, row, index)} >
            <Button>{num > 0 ? 
            `View ${num +1} Certificates` : `View ${num +1} Certificate`  }</Button>
          </a>
        );
      }
      else {
        return "-";
      }
    }
    else {
      if ((this.props.role === 'admin') && (row['participated'] === true || row['updatedParticipatedAttendence'] === true)) {
        return (
          <a>
            <Button onClick={this.getCertificate.bind(this, row.id)}>View Certificate</Button>
          </a>
        );
      }
      else if (
          !this.state.employeeAttendanceOverride &&
        row["participated"] === true &&
        JSON.parse(readCookie("userData")).id === row.id
      ) {
        return (
          <a>
            <Button onClick={this.getCertificate.bind(this, row.id)}>View Certificate</Button>
          </a>
        );
      } else if (
        // organisationDataContext.eePreferences
        //   .enableEmployeeAttendance 
          this.state.employeeAttendanceOverride &&
        row["updatedParticipatedAttendence"] === true &&
        JSON.parse(readCookie("userData")).id === row.id
      ) {
        return (
          <a  onClick={this.getCertificate.bind(this, row.id)} >
            <Button>View Certificate</Button>
          </a>
        );
      }
      else {
        return "-";
      }
    }
  }
  hideEmployeeEditModal() {
    this.setState({ showEmployeeEditModal: false });
  }

  showEmployeeEditModal(attendeeDetails, attendeeIndex) {
    this.setState({
      showEmployeeEditModal: true,
      attendeeId: attendeeDetails,
      editingRowIndex: attendeeIndex,
    });
  }

  toggleChecked = (id) => {
     
      const newList=this.state.checkBoxList.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
      this.setState({
        checkBoxList:newList
      })
     
  };

  approvedHours(cell, row) {
    if (cell && cell !== "N/A") {
      if (
        row["updatedParticipatedAttendence"] === true &&
        row["updatedStartTime"] !== "" &&
        row["updatedEndTime"] !== ""
      ) {
        return (
          row["updatedStartTime"] +
          " - " +
          row["updatedEndTime"] +
          " (" +
          cell +
          " hours)"
        );
      } else if (
        row["updatedParticipatedAttendence"] === true &&
        row["updatedStartTime"] === "" &&
        row["updatedEndTime"] === ""
      ) {
        return (
          row["startTime"] +
          " - " +
          row["endTime"] +
          "(" +
          row["hoursClocked"] +
          " hours)"
        );
      } else {
        return "-";
      }
    } else return "-";
  }

  removeParticipant(id,data) {
       let voId = this.state.selectedOpportunity.id;

    swal({
      title:
        "Are you sure you want to remove this participant from this opportunity?",
      text: "You won't be able to revert this.",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Remove!",
      cancelButtonText: "Cancel",
    })
      .then((result) => {
        let attendeeData = this.state.attendeeData.filter(
          (data) => data.id !== id
        );
        const arrayWithChecked = originalArray.map((id) => ({
          id: id.employeeId,
          checked: false,
        }));
        console.log(arrayWithChecked);
        this.setState({ attendeeData:attendeeData ,
          checkBoxList:arrayWithChecked
         });

        if (result) {
          fetch(
            configs.BACKEND_API_URL +
            "/ee/admin/volunteer-opportunity/" +
            voId +
            "/" +
            id,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Auth: JSON.parse(readCookie("access_token")).access_token,
              },
            }
          )
          .then((data) => data.json())
            .then(
              function (data) {
                if (data.status === "ok") {
                  swal("",
                    "The participant was successfully removed.",
                    "success");
                  window.location.reload();
                } else {
                  swal("Oops!", data.message, "error");
                  window.location.reload();
                }
              }.bind(this)
            );
        }
      })
      .catch(() => { });
  }

  deleteParticipant(cell, row,props) {
    // console.log("deleteParticipant",props);
    if (row.id && this.props.role === 'admin')  {
      return <Button onClick={this.removeParticipant.bind(this, row.id,row)}>Remove</Button>;
    }
  }
  familyFriendsModal(cell, row, _, index) {
    const data = this.props.friendsList
      ? this.props.friendsList?.map((data) => {
        if (data?.volunteerId === row?.id) {
          // console.log(data);
          return data.friendsCount;
        }
      })
      : null;
    return data ? (
      <a onClick={this.show.bind(this, row, index)}> {data}</a>
    ) : null;
  }
  familyFriendsNameModal(cell, row, _, index) {
    const data = this.props.friendsList
      ? this.props.friendsList?.map((data) => {
        if (data?.volunteerId === row?.id) {
          // console.log(data);
          return data.friends?.map(person => person?.name)?.join(', ');
        }
      })
      : null;
    return data ? (
      <p> {data}</p>
    ) : null;
  }

  familyParticipationTime(cell, row, _, index) {
    var num = 0;
    const data = this.props.friendsList
      ? this.props.friendsList.map((data) => {
        if (data.volunteerId === row.id) {
          data.friends.map((data) => {
            if (data.hasOwnProperty("attended")) {
              if (data.attended === true) {
                num++;
              }
            }
          });
        }
      }) : null;
      var sumTime = 0;
      if (this.props.selectedOpportunity.offsetHours && this.props.selectedOpportunity.offsetHours.friendsAndFamily) {
          sumTime = moment.duration(`${this.props.selectedOpportunity.offsetHours.friendsAndFamily.hours}:00`).asMinutes();
        }
    // if (cell && cell !== "N/A") {
      // console.log("cell",cell)
      if (
        row["updatedParticipatedAttendence"] === true &&
        row["updatedStartTime"] !== "" &&
        row["updatedEndTime"] !== ""
      ) {
                let cellTime = row["updatedHoursTime"].split(":");
                  let hoursClocked = cellTime[0] * num + ":" + cellTime[1] * num;
                  sumTime += moment.duration( hoursClocked).asMinutes();
            
              let h = sumTime / 60,
               m = sumTime % 60;
                if (String(m).length < 2) m = `0${m}`;
                if (String(h).length < 2) h = `0${h}`;
                var totalHours = `${h}:${m}`
                // console.log("totalHours === >>> ",totalHours);
        let cell = `${h +" hours"} `+ `${m + " minutes"}`  ;
        if(totalHours === "0:0"){
          return "00 hours 00 minutes"
        }
        else {
          return totalHours.split(":")[0].split(".")[0]  + ' hours ' + totalHours.split(":")[1] + ' minutes'
        }
        
        // return cell 
      }
      else if (row["updatedParticipatedAttendence"] === false) {
        return 0 + " hours " + 0 + " minutes"
      }
      else if (
        row["updatedParticipatedAttendence"] === true &&
        row["updatedStartTime"] === "" &&
        row["updatedEndTime"] === ""
      ) {
        let cellTime = row["hoursClocked"].split(":");

        let hoursClocked = cellTime[0] * num + ":" + cellTime[1] * num;
        sumTime += moment.duration( hoursClocked).asMinutes();
  
    let h = sumTime / 60,
     m = sumTime % 60;
      if (String(m).length < 2) m = `0${m}`;
      if (String(h).length < 2) h = `0${h}`;
      var totalHours = `${h}:${m}`
      let cell = `${h +" hours"} `+ `${m + " minutes"}`
        // let cellTime = row["hoursClocked"].split(":");
        // let cell = `${cellTime[0] * num +" hours"} `+ `${cellTime[1] * num + " minutes"}`  ;
        if(totalHours === "0:0"){
          return "00 hours 00 minutes"
        }
        else {
          return totalHours.split(":")[0].split(".")[0]  + ' hours ' + totalHours.split(":")[1] + ' minutes'
        }
        
        // return cell ;
      } else if (
        row["updatedParticipatedAttendence"] === "" &&
        row["startTime"] &&
        row["endTime"]
      ) {
        let cellTime = row["hoursClocked"].split(":");
        let hoursClocked = cellTime[0] * num + ":" + cellTime[1] * num;
        sumTime += moment.duration( hoursClocked).asMinutes();
  
    let h = sumTime / 60,
     m = sumTime % 60;
      if (String(m).length < 2) m = `0${m}`;
      if (String(h).length < 2) h = `0${h}`;
      var totalHours = `${h}:${m}`
let cell = `${h +" hours"} `+ `${m + " minutes"}`
        if(totalHours === "0:0"){
          return "00 hours 00 minutes"
        }
        else {
          return totalHours.split(":")[0].split(".")[0]  + ' hours ' + totalHours.split(":")[1] + ' minutes'
        }
        // let cell = `${cellTime[0] * num +" hours"} `+ `${cellTime[1] * num + " minutes"}`  ;
        // totalHours === "0:0" ? "00 hours 00 minutes" : this.state.totalGuestHours.split(":")[0].split(".")[0]  + ' hours ' + this.state.totalGuestHours.split(":")[1] + ' minutes'
        // return cell ;
      } 
      // else {
      //   return cell + " hours";}
    // } else return "";
  }
  roundMinutes(t) {
    function format(v) { return v < 5 ? '0' + v: v; }

    var m = t.split(':').reduce(function (h, m) { return h * 60 + +m; });
     if(m <30 ){  
      m = Math.floor(m / 30) * 30;
     }else { 
       m = Math.ceil(m / 30) * 30;
     }
    return [Math.floor(m / 60), m % 60].map(format).join('.');
}

   createCustomExportCSVButton(attendeeData) {
     let mxf=0
     this.props.mergeData.map(it=>{
       mxf=mxf<(it.friendsCount||0)?(it.friendsCount||0):mxf
      })
      console.log(this.props.mergeData,mxf,'scs');
     const data = this.props.mergeData.map(item => {
      const formattedFriends = (item.friends || []).map((friend, index) => ({
        [`Guest-${index + 1} Name`]: friend.name || "",
        [`Guest-${index + 1} Age`]: friend.age || "",
        [`Guest-${index + 1} Gender`]: friend.gender || "",
        [`Guest-${index + 1} Status`]: friend.hasOwnProperty("attended")
          ? friend.attended === true
            ? "Approved"
            : friend.attended === false
            ? "Unapproved"
            : ""
          : "",
        [`Guest-${index + 1} Status-Approved`]: friend.hasOwnProperty("adminUpdatedAttendance") &&
          friend.hasOwnProperty("startTime")
          ? friend.adminUpdatedAttendance === true && friend.startTime !== null
            ? "Approved"
            : friend.adminUpdatedAttendance
            ? "Unapproved"
            : ""
          : ""
      }));
      //  console.log("🚀 ~ file: SingleOpportunityParticipantsList.js:1071 ~ data ~ item:", item.fullName, item.guestHours, item.guestHour)
       return {
         "Hexa ID": item.id,
         "Name": item.fullName,
         'EmployeeId': item.employeeId,
         "Email": item.email,
         "Role": item.role,
         "Mobile": item.mobileNo || "",
         "Whatsapp": item.whatsapp || "",
         "Alternative Number": item.alternateMobileNumber || "",
         "Gender": item.gender,
         "Designation": item.designationAtOrganisation || "",
         "Department": item.department || "",
         "Transport Required": item.transportRequired || "No",
         "Selected Transportation Route": item.transportRequired=='Yes'? item.selectedTransportedRoute || "-":'-',
         "Selected Option": item.selectedGroup || "-",
         "Participation Status": item.participated ? "Attendance Declared"
           : (!item.participated && item.declared ? "Missed" : ""),
         "Hours": item.hasOwnProperty("hoursClocked") && item.hoursClocked === "N/A" ? ("") :
           (item.hasOwnProperty("hoursClocked") && item.hoursClocked !== "N/A" ? (moment(item.hoursClocked, "HH:mm").format("HH.mm")) : ("")),

         // Adding all data from pulse
         "DOJ": item.doj,
         "CURRENT_DESIGNATION": item.designationAtOrganisation,
         "BUSINESS_UNIT": item.business_unit,
         "OU NAME": item.ou_Name,
         "REPORTING MANAGER": item.reporting_manager_emp_ID,
         "REPORTING_MANAGER_NAME": item.reporting_manager_name,
         "REPORTING_MANAGER_DESIGNATION": item.reporting_manager_designation || "",
         "REPORTING_MANAGER_MAILID": item.reporting_manager_email || "",
         "FUNCTIONAL REPORTING MANAGER": item.funtional_reporting_manager_emp_ID || "",
         "COUNTRY": item.country || "",
         "EMPLOYEE STATUS": item.employee_status || "",
         "STATUS": item.status || "",
         "BRANCH_CODE": item.branch_code || "",
         "BRANCH ADDRESS": item.branch_address || "",
         "CURRENT_LOCATION": item.location || "",
         "CITY_NAME": item.city || "",
         "REGION": item.region || "",
         "ZONE": item.zone || "",
         "Shift Start Time": item.shift_start || "",
    
         // moment(item.hoursClocked,"HH:mm").format("HH.mm")  
         "Experience": item.experience,
         "Question-1": item.questionSelectedOption1,
         "Question-2": item.questionSelectedOption2,
         "Question-3": item.questionSelectedOption3,
         ...(item.addUnitOfMeasurement === 'Yes' && {
          [item.unitOfMeasurement]: item.unitOfMeasurementanswer
        }),
         "Reason": item.reason,
         "Admin Approved Status": item.updatedParticipatedAttendence === true ?
           "Approved" : (item.updatedParticipatedAttendence === false ? "Unapproved" : ""),
         "Admin Approved Hours": item.updatedHoursTime,
         "Admin Approved Start Time": item.updatedStartTime,
         "Admin Approved End Time": item.updatedEndTime,
         "Guest-Count": item.friendsCount,

         //  ===================from here =================
         ...Object.assign({}, ...formattedFriends),
         //  ======================== till here friends guest =================================
         
         "Admin-Name": item.adminName,
         "startTime": item.hasOwnProperty("startTime") ? item.startTime : "",
         "endTime": item.hasOwnProperty("endTime") ? item.endTime : "",
         "Admin Approved Hours(Guest)": item.guesthour || '-',// && moment(moment(item.guesthour, "HH:mm")).isValid() ? moment(item.guesthour, "HH:mm").format("HH:mm"): '-',
         "AdminApprovedStartTime(Guest)": item.guestAttended > 0 ? (item.hasOwnProperty("startTime") && item.updatedParticipatedAttendence === "" ? item.startTime : item.updatedStartTime) : "",
         "AdminApprovedEndTime(Guest)": item.guestAttended > 0 ? (item.hasOwnProperty("endTime") && item.updatedParticipatedAttendence === "" ? item.startTime : item.updatedStartTime) : "",
       }
    
  
    
     });
     const generateGuestHeaders = (numberOfGuests) => {
      const guestHeaders = [];
      for (let i = 1; i <= numberOfGuests; i++) {
        guestHeaders.push(
          { label: `Guest-${i} Name`, key: `Guest-${i} Name` },
          { label: `Guest-${i} Age`, key: `Guest-${i} Age` },
          { label: `Guest-${i} Gender`, key: `Guest-${i} Gender` },
          { label: `Guest-${i} Applied-Status`, key: `Guest-${i} Status` },
          { label: `Guest-${i} Approved-Status`, key: `Guest-${i} Status-Approved` }
        );
      }
      return guestHeaders;
    };
    

    const headers = [
      {label:"Hexa ID",key:"Hexa ID"},
      { label: "Name", key: "Name" },
      { label: "Employee Id", key: "EmployeeId" },
      { label: "Email", key: "Email" },
      { label: "Role", key: "Role" },
      { label: "Mobile", key: "Mobile" },
      { label: "Alternative Number", key: "Alternative Number" },
      { label: "Whatsapp number", key: "Whatsapp" },
      { label: "Department", key: "Department" },
      {label:"Designation",key:"Designation" },
      { label: "Gender", key: "Gender" },
      { label: "Transport Required", key: "Transport Required" },
      { label: "Selected Transportation Route", key: "Selected Transportation Route" },
      { label: "Selected Option", key: "Selected Option" },
      {label:"Participation Status",key:"Participation Status"},
      {label:"Applied Start Time",key:"startTime"},
     {label:"Applied End Time",key:"endTime"},
     { label: "Applied Hours", key: "Hours" },
    
      

     // adding IBL pulse headers
        {
          "key": "REPORTING_MANAGER_DESIGNATION",
          "label": "REPORTING MANAGER DESIGNATION"
        },
        {
          "key": "REPORTING_MANAGER_MAILID",
          "label": "REPORTING MANAGER MAILID"
        },
        {
          "key": "FUNCTIONAL REPORTING MANAGER",
          "label": "FUNCTIONAL REPORTING MANAGER"
        },
        {
          "key": "COUNTRY",
          "label": "COUNTRY"
        },
        {
          "key": "EMPLOYEE STATUS",
          "label": "EMPLOYEE STATUS"
        },
        {
          "key": "STATUS",
          "label": "STATUS"
        },
        {
          "key": "BRANCH_CODE",
          "label": "BRANCH CODE"
        },
        {
          "key": "BRANCH ADDRESS",
          "label": "BRANCH ADDRESS"
        },
        {
          "key": "CURRENT_LOCATION",
          "label": "CURRENT LOCATION"
        },
        {
          "key": "CITY_NAME",
          "label": "CITY NAME"
        },
        {
          "key": "REGION",
          "label": "REGION"
        },
        {
          "key": "ZONE",
          "label": "ZONE"
        },
        {
          "key": "Shift Start Time",
          "label": "Shift Start Time"
        },


     {label:"Admin Approval Status",key:"Admin Approved Status"},
      {label :"Admin Approval Start Time",key:"Admin Approved Start Time"},
      {label :"Admin Approval End Time",key:"Admin Approved End Time"},
     //  convert into hours as in 6.5
    // admin approved Time (Both) 
     {label:"Admin Approved Hours (Volunteers)",key:"Admin Approved Hours"},// convwertinto 1.5 hour format
     {label:"Experience", key: "Experience"},
     {label:"How much of an impact do you feel your volunteer work had?",key:"Question-1"},
     { label:"How satisfied are you with your volunteer experience?",key:"Question-2"},
     { label:"How likely are you to participate in a similar event again?",key:"Question-3"},
     ...(this.props.selectedOpportunity.addUnitOfMeasurement === 'Yes'
      ? [{ label: this.props.selectedOpportunity.unitOfMeasurement, key: this.props.selectedOpportunity.unitOfMeasurement }]
      : []),
     {label:"Reason For Missing Event",key:"Reason"},
     {label: "Guest-Count",key: "Guest-Count"},


    //  ============================== from here guest list ====================
    //  {label:"Guest-Name",key:"Guest-Name"},
    //  {label: "Guest-Age",key: "Guest-Age"},
    //  {label:"Guest-Gender",key:"Guest-Gender"},
    //  {label:"Guest-Applied-Status",key:"Guest-Status"},
    //  {label:"Guest-Approved-Status",key:"Guest-Status-Approved"},
    ...generateGuestHeaders(mxf),


    

// ==========================till here guest list ===============
{label :"Admin Approval Start Time(Guest)",key:"AdminApprovedStartTime(Guest)"},
{label :"Admin Approval End Time(Guest)",key:"AdminApprovedEndTime(Guest)"},
{label:"Admin Approved Hours (Guest)",key:"Admin Approved Hours(Guest)"},
    // convwertinto 1.5 hour format
     {label: "Admin-Name",key: "Admin-Name"},
      // {label:"Admin-APproval-date"}
    ];


    return (
      <>{ this.props.role ==="admin" ?(<>
        <CSVLink
          headers={headers}
          data={data}
          onClick={() => {
          }}
          filename={
            this.state.selectedOpportunity.title +
            " - Participants - " +
            String(moment().unix()) +
            ".csv"
          }
          target="_blank">
          <ExportCSVButton
            style={{
              background: 'var(--themeColor)',
              borderColor: 'var(--themeColor)',
              boxShadow: "1px 1px 1px #515356"
            }}
            // className="btn btn-endorse"
            btnText='Export to CSV' />
        </CSVLink>
      </>)  : null
      

      }</>
      

    );
  }


   startScrolling = (obj, spd, btn, direction) => {
     var travelX = 0, stop = false;
     if ($(obj).css('transform') !== 'none') travelX = parseInt($(obj).css('transform').split(',')[4].trim());

     if (direction === 'left') {
       let max = $('.react-bs-table').width() - $(obj).width();
      //  let max = $('.react-bootstrap-table').width() - $(obj).width();
       if ((travelX - spd) < max) {
         travelX = max;
         stop = true;
         $(obj).css('transform', 'translateX(' + travelX + 'px)');
       } else travelX -= spd;
     } else if (direction === 'right') {
       if ((travelX + spd) > 0) {
         travelX = 0;
         stop = true;
         $(obj).css('transform', 'translateX(' + travelX + 'px)');
       } else travelX += spd;
     }

     if (!this.scrolling || stop) {
       obj.stop();
     } else {
       $(obj).css('transform', 'translateX(' + travelX + 'px)');
       // recursively call startScrolling while mouse is hovered
       setTimeout(() => {
         this.startScrolling(obj, spd, btn, direction);
       }, spd);
     }
   }

  // onOfficeOrDeptChange(field, value) {
  //   		this.setState({ [field]: value.target.value }, this.filterAttendeeAnalytics);
  //   	}  
      filterAttendeeAnalytics() {
    		let urlParams = '?',
    			path = this.props.path;
    		if (this.state.office !== '') {
    			if (urlParams !== "?") {
    				urlParams += '&';
    			}urlParams += 'listId=' + this.state.office;
    		}
    			if (this.state.department !== '') {
    			if (urlParams !== "?") {
    				urlParams += '&';
    			}
    			urlParams += 'department=' + this.state.department;
          }
        if (this.state.selectedCity !== '') {
          if (urlParams !== "?") {
            urlParams += '&';
          }
          urlParams += 'city=' + this.state.selectedCity;
        }
    		if (urlParams === '?') urlParams = '';
    
    		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path + '/attendee-analytics' + urlParams, {
    			method: "GET",
    			headers: {
    				'Auth': JSON.parse(readCookie('access_token')).access_token
    			},
    		}).then(function (response) {
    			return response.json();
    		}).then(function (data) {
    			if (data.status === 'ok') {
    				this.setState({attendeeData: data.attendees});
    			}
    		}.bind(this));
    	}

      handleSearch(event) {
        const searchQuery = event.target.value.toLowerCase();
          
      //  console.log(this.searchInputRef.current);
       
     
          const filteredlist = this.state.attendeeData.filter(item =>
            item.fullName.toLowerCase().includes(searchQuery)
          );
           
        this.setState({
        searchText:searchQuery,
        filteredlist:filteredlist,
        edit:true
        })
        
         
      }
      
      createCustomExportCSVButtonForExternalParticipants(attendeeData){

        const headers = [ 
          { label: "Name", key: "name" },
          { label: "Nature of relation with organization ", key: "relationWithOrganisation" },
          { label: "Email", key: "participantEmailId" },
          {label:"Mobile Number",key:"mobileNumber"},
          {label:"POC Email ",key:"participantPocEmailId"},
          {label:"Start Time",key:"startTime"},
         {label:"End Time",key:"endTime"},
         { label: "Hours Clocked", key: "hoursClocked" },
        ];
        return (
          <>{ this.props.role ==="admin"  && this.state.externalParticipantsData.length > 0 ? ( <>
            <CSVLink
              headers={headers}
              data={attendeeData}
              onClick={() => {
              }}
              filename={
                this.state.selectedOpportunity.title +
                " - External-Participants - " +
                String(moment().unix()) +
                ".csv"
              }
              target="_blank">
              <ExportCSVButton
                style={{
                  marginTop:"-5%",
                  background: 'var(--themeColor)',
                  borderColor: 'var(--themeColor)',
                  boxShadow: "1px 1px 1px #515356"
                }}
                // className="btn btn-endorse"
                btnText='Export to CSV' />
            </CSVLink>
          </>)  : null
          }</>
        );
      }

      AddExternalParticipantsTable(){
        this.setState({allowExternalParticipants :true ,enableExternalParticipants : false})
        // this.setState({allowExternalParticipants :true,enableExternalParticipants:true})
      }
      addExternalParticipants(){
        this.setState({showExternalParticipantsModal: true });
      }
     hideExternalParticipantsModal(){
      // this.setState({enableExternalParticipants : true});
      this.setState({showExternalParticipantsModal: false });
      this.setState({showExternalParticipantsEditModal:false});
     }

     getExternalParticipantCertificate(id) {
      
      this.setState({showExternalCertificate:false});
      fetch(
        configs.BACKEND_API_URL +
        "/ee/external-participants/certificate/"+ id,
        {
          method: "GET",
          headers: {
            Auth: JSON.parse(readCookie("access_token")).access_token,
          },
        }
      )
        .then((data) => data.json())
        .then((data)=> {
          
           var a = document.createElement('a');
            a.href = data.pdfUrl;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
          }
        );
    }
     viewExternalParticipantsCertificate(cell, row) {
      
        return (
          <a>
              <Button onClick={this.getExternalParticipantCertificate.bind(this, row._id)} >View certificate</Button>  
           </a>
        )
      }
     
  deleteExternalParticipant(id,data) {
         // let voId = this.state.selectedOpportunity.id;
        //  console.log("deleter dsfnkshfkjk",id);
 
     swal({
       title:
         "Are you sure you want to remove this participant from this opportunity?",
       text: "You won't be able to revert this.",
       type: "warning",
       showCancelButton: true,
       confirmButtonText: "Yes, Remove!",
       cancelButtonText: "Cancel",
     })
       .then((result) => {
        //  let attendeeData = this.state.attendeeData.filter(
        //    (data) => data.id !== id
        //  );
 
        //  this.setState({ attendeeData: attendeeData });
 
         if (result) {
           fetch(
             configs.BACKEND_API_URL +
             "/ee/external-participants/" + id,
             {
               method: "DELETE",
               headers: {
                 "Content-Type": "application/json",
                 Auth: JSON.parse(readCookie("access_token")).access_token,
               },
             }
           )
           .then((data) => data.json())
             .then(
               function (data) {
                 if (data.status === "ok") {
                   swal("",
                     "The participant was successfully removed.",
                     "success");
                     this.getExternalParticipantsList();
                 } else {
                   swal("Oops!", data.message, "error");
                   this.getExternalParticipantsList();
                  //  window.location.reload();
                 }
               }.bind(this)
             );
         }
       })
       .catch(() => { });
   }



removeExternalParticipant(cell, row) {
  
 if (row._id) {
   return <Button onClick={this.deleteExternalParticipant.bind(this, row._id,row)}>Remove</Button>;
 }
}

      getExternalParticipantsHours(cell, row){
        return (
          row["startTime"] +
          " - " +
          row["endTime"] +
          " (" +
          row["hoursClocked"] +
          " hours)"
        );
      }
   
      showExternalParticipantsEditModal(attendeeDetails, attendeeIndex){
        this.setState({
          showExternalParticipantsEditModal: true,
              attendeeId: attendeeDetails,
              editingRowIndex: attendeeIndex,
            });
      }

      editExternalParticipants(cell, row, _, index){
        return (
              <Button
                className="btn-sq btn-edit"
                onClick={this.showExternalParticipantsEditModal.bind(this, row, index)}
              >
                Edit 
              </Button>
            );
      }
  

  //  handleCitySelection(value) {
  //   //  console.log("🚀 ~ file: MakeLive.js:287 ~ handleSearch ~ value", value)
  //    // let citiesToDisplay = this.state.cities.filter(city => city.value.toLowerCase().includes(value.toLowerCase()));
  //    // citiesToDisplay = citiesToDisplay.map(city => city.value);
  //    this.setState({ selectedCity: value, citySearchQuery: value }, this.filterAttendeeAnalytics);

  //  }
  //  handleCitySearch(value) {
  //   //  console.log("🚀 ~ file: VolunteerOpportunitiesFilter.js:352 ~ VolunteerOpportunitiesFilter ~ handleCitySearch ~ value", value)
  //    const citiesToDisplay = this.state.cities.filter(city => city.value.toLowerCase().includes(value.toLowerCase()));
  //    this.setState({ citiesToDisplay, citySearchQuery: value });
  //  }

   render() {
    //  const cityoptions = this.state.citiesToDisplay.map(function (city, index) {
    //    return (
    //      <Option key={index} value={city.value}>{city.label}</Option>
    //    )
    //  });
    const options = { 
      exportCSVBtn: () => this.createCustomExportCSVButton(this.state.attendeeData), 
    };
    const externalParticipantsOptions = {exportCSVBtn: () => this.createCustomExportCSVButtonForExternalParticipants(this.state.externalParticipantsData)};

    if (
      this.state.selectedOpportunity !== null &&
      this.state.selectedOpportunity.hasOwnProperty("title")
    ) {
      return (
        <OrgDataContext.Consumer>
          {(organisationData) => {
            organisationDataContext = organisationData;
           return (<div>
            <DocumentTitle title={this.state.selectedOpportunity.title} />
            <Col md={12} className="mt30">
              <ToastContainer
                position="bottom-right"
                autoClose={50000}
                hideProgressBar={true}
                closeOnClick={false}
                newestOnTop={false}
                pauseOnHover={true}
              />
              {this.props.loading 
                // !this.props.friendsList 
                // || !this.state.attendeeData 
                ? (
                   <div className='data-loader' />
                // <i className="fa fa-spin fa-spinner fa-5x"></i>
              ) : (
                <>
                      <div><button onClick={this.props.goBack} className="btn" 
                      style={{ backgroundColor: 'var(--themeColor)', color: 'white', marginLeft: "3px", marginBottom: "15px"}}>
                    <i className="fa fa-chevron-left mr5"></i>Back to event overview tab</button></div>
                  {this.state.selectedOpportunity.status === "Live" || 
                  this.state.selectedOpportunity.status === "Completed" 
                  || this.state.selectedOpportunity.status === "Ongoing" ? (
                  <>
                  <Row className="dashboard-motivation-participant-list">
                    <Col md={3} >
                      <div class="right-txt-wrp-volunteer-participant-list" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/employee.png" />
                          <div >
                            <p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
                              { this.state.selectedOpportunity.status === "Live" 
                              || this.state.selectedOpportunity.status === "Ongoing" ? 
                              this.props.selectedOpportunity?.attendees?.length :(this.state.selectedOpportunity.status === "Completed"? <>
                              {`${this.props.tableData[0]?.totalApprovedCount}/${this.props.tableData[0]?.signups}`}</> :null) }
                            </p>
                            { this.state.selectedOpportunity.status === "Live"  || this.state.selectedOpportunity.status === "Ongoing" ?
                             <p className="dashboard-motivational-elements-2-p">
                              Total Employee Registrations </p> :
                              (this.state.selectedOpportunity.status === "Completed"?<p className="dashboard-motivational-elements-2-p">
                              Total Employee Volunteers </p> :null)}
                          </div>
                        </div>
                      </div>
                    </Col>
                    {this.state.selectedOpportunity.status === "Completed" ?
                    <Col md={3}>
                      <div class="right-txt-wrp-volunteer-participant-list" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/clock.png" />
                          <div style={{ marginLeft: 10 }}>
                            {this.state.selectedOpportunity.status === "Completed" ? (
                              <p class="right-txt-wrp-title" style={{ marginTop: 10 ,letterSpacing:0 }}>
                                {this.props.tableData[0].employeeHours.split(":")[0].split(".")[0]  + ' hours ' + this.props.tableData[0].employeeHours.split(":")[1] + ' minutes' }
                              </p>
                            ) : (
                              <p class="right-txt-wrp-title" style={{ marginTop: 10 ,letterSpacing:0 }}>
                                0
                              </p>
                            )}
                            <p className="dashboard-motivational-elements-2-p">Total Employee Volunteering Hours</p>
                          </div>
                        </div>
                      </div>

                    </Col>:null }
                    {
                      this.state.tableData.hasOwnProperty("registrationsAllowed") && (this.state.selectedOpportunity.status === "Completed" || this.state.selectedOpportunity.status === "Live" || this.state.selectedOpportunity.status === "Ongoing" ) ? (this.state.tableData.registrationsAllowed > 0 ? (
                        <>
                          <Col md={3}>
                            <div class="right-txt-wrp-volunteer-participant-list" >
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/guest2.png" />
                                <div style={{ marginLeft: 10 }}>
                                  <p class="right-txt-wrp-title" style={{ marginTop: 10  }}>
                                    {/* {this.state.totalGuestParticipation} */}
                                    { this.state.selectedOpportunity.status === "Live" || this.state.selectedOpportunity.status === "Ongoing" ? this.props.selectedOpportunity?.friends?.length : (this.state.selectedOpportunity.status === "Completed"? <>
                                    {`${this.props.tableData[0]?.guestCount}/${this.props.selectedOpportunity?.friends?.length}`} </>: null) }
                                  </p>
                                  {this.state.selectedOpportunity.status === "Live" || this.state.selectedOpportunity.status === "Ongoing" ? <p className="dashboard-motivational-elements-2-p"> Total Guest Registrations</p>:
                                  (this.state.selectedOpportunity.status === "Completed" ?<p className="dashboard-motivational-elements-2-p"> Total Guest Volunteers</p> :null) }
                                </div>
                              </div>
                            </div>
                          </Col>

                          {this.state.selectedOpportunity.status === "Completed" ? <Col md={3}>
                            <div class="right-txt-wrp-volunteer-participant-list" >
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/clock.png" />
                                <div style={{ marginLeft: 10 }}>
                                  <p class="right-txt-wrp-title" style={{ marginTop: 10 ,letterSpacing:0}}>
                                    {this.props.tableData[0].guestHours === "0:0" ? "00 hours 00 minutes" : this.props.tableData[0].guestHours.split(":")[0].split(".")[0]  + ' hours ' + this.props.tableData[0].guestHours.split(":")[1] + ' minutes' }
                                    </p>
                                  <p className="dashboard-motivational-elements-2-p"> Total Guest Volunteering Hours</p>
                                </div>
                              </div>
                            </div>
                          </Col> :null}
                        </>
                      ) : null) : null


                    }
                  </Row>
                  </>) : (null)}
                      <div className="single-opportunity-analytics-page"
                        // style={{ 'marginTop': '50px' }}
                      >
                    <div className="analytics-container">
                      <div className="attendee-analytics">
                        <Row >
                          
                              {/* <div className="attendee-analytics"> */}
 								<div className="attendee-analytics-header">

                               
									<span className="heading"></span>
                               {
                                 this.props.role === "admin" && this.props.allowHistoricalEventCreation ?
                                   (this.props.selectedOpportunity.status === "Completed" &&
                                     (!this.props.selectedOpportunity.offsetHours && (this.props.selectedOpportunity.offsetHours.employees.hours + this.props.selectedOpportunity.offsetHours.friendsAndFamily.hours + this.props.selectedOpportunity.offsetHours.externalParticipants.hours)) ?
                                     <div className="participated-in-opportunity">
                                       <span className="title">
                                         {/* Declare Your attendance */}
                                       </span>

                                       <a
                                         onClick={this.showOffsetTimeModal.bind(
                                           this
                                         )}
                                         className="btn btn-sq btn-le btn-declare-attendance"
                                       >
                                         Add offset hours
                                       </a>
                                     </div> : <div className="participated-in-opportunity">
                                       <span className="title">
                                         {/* Declare Your attendance */}
                                       </span>

                                       <a
                                         onClick={this.showOffsetTimeModal.bind(
                                           this
                                         )}
                                         className="btn btn-sq btn-le btn-declare-attendance"
                                       >
                                         Edit offset hours
                                       </a>
                                     </div>)
                                   : null
                               }
                               {/* <div className="filters-container">
 										<div className="form-group mb0">
 											<label className="control-label">Office:</label>
 											<select className="inline" value={this.state.office}
 												onChange={this.onOfficeOrDeptChange.bind(this, 'office')}>
 												<option value=''>All</option>
 												{this.state.offices.map(function (office, index) {
 													return (
 														<option value={office.id}>{office.name}</option>
 													)
 												})}
 											</select>
 										</div> */}
                               <div className="filters-container">
                                 {/* <div className="form-group mb0">
                                   <label className="control-label">City:</label>
                                   <AutoComplete
                                     // value={this.state.selectedCity}
                                     value={this.state.citySearchQuery}

                                     dataSource={cityoptions}
                                     style={{ width: '130px' }}
                                     onSelect={this.handleCitySelection.bind(this)}
                                     onSearch={this.handleCitySearch.bind(this)}
                                     placeholder="All Cities"
                                   /> */}
                                 {/* </div> */}
                    
										{/* <div className="form-group mb0">
											<label className="control-label">Department:</label>
											<select className="inline" value={this.state.department}
												onChange={this.onOfficeOrDeptChange.bind(this, 'department')}>
												<option value=''>All</option>
												{this.state.departments.map(function (department, index) {
													return (
														<option value={department}>{department}</option>
													)
										})}
											</select>
									</div> */}
                     {/* </div> */}
                     </div>
                          
                     </div>

                          {this.props.role === "admin" &&
                          <div className="participated-in-opportunity" style={{cursor:'pointer'}}>
                          <span className="title" >
                            {/* Declare Your attendance */}
                          </span>
                          

                          <a
                            onClick={this.approveAttendence.bind(
                              this
                            )}
                            style={{position:'relative'}}
                            className="btn btn-sq btn-le btn-declare-attendance"
                          >
                             
                            Mark Attendance
                          </a>
                        </div>
                              }
                          {this.props.role === "admin" &&
                          <div className="participated-in-opportunity" style={{cursor:'pointer'}}>
                          <span className="title" >
                            {/* Declare Your attendance */}
                          </span>
                          

                          <a
                            style={{margin:'10px 0', maxWidth:'50%'}}
                            onClick={this.approveAttendence.bind(
                              this,true
                            )}
                            className="btn btn-sq btn-le btn-declare-attendance"
                          >
                             
                             Marked participant attendance with declaration.
                          </a>
                        </div>
                              }

                             {this.state.attendeeData ? (
                               <span id="drag-table-left">{'<'}</span>
                             ) : (null)}
                             {this.state.attendeeData ? (
                               <span id="drag-table-right">{'>'}</span>
                             ) : (null)}
                          <BootstrapTable data={this.state.attendeeData} search={this.props.role === "admin" ? true : false} hover exportCSV responsive options={options}
                            searchPlaceholder='Search Participant By their Name'
                            csvFileName={ this.state.selectedOpportunity.title + " - Participants - " + String(moment().unix()) + ".csv"}>

                            <TableHeaderColumn 
                             width="30px"
                             dataField="employeeId"                            
                             dataFormat={ this.checkbox}
                             formatExtraData={[ this.state.checkBoxList,this.state.selectAllList,(id=>{
                              console.log(id,this.state.checkBoxList);
                              this.toggleChecked(id)})]}
                             tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            >
                              <input style={{cursor:'pointer'}} type="checkbox"  
                              checked={this.state.selectAllList}   
                              onChange={() =>{this.setState({
                                selectAllList:!this.state.selectAllList
                              })}}  
                    />
                            </TableHeaderColumn>
                            { this.props.role === 'admin'? <TableHeaderColumn isKey dataField="id" width="200px" > Hexa ID </TableHeaderColumn> :<TableHeaderColumn isKey dataField="id" width="200px" hidden> Hexa ID </TableHeaderColumn>}
                            {this.props.role === 'admin' ? (
                              <TableHeaderColumn
                                width="100px"
                                dataField="employeeId"
                                dataSort
                                export
                              >
                                ECN
                              </TableHeaderColumn>
                            ) : null}
                            <TableHeaderColumn
                              width="240px"
                              dataField="fullName"
                              export
                              dataSort
                              dataFormat={this.checkName}
                              formatExtraData={this.props}
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            >
                             Employee Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="mobileNo"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Mobile
                            </TableHeaderColumn>
                            
                            {this.state.tableData.hasOwnProperty(
                              "registrationsAllowed"
                            ) ? (
                              <TableHeaderColumn
                                width="140px"
                                dataField="friendsCount"
                                dataFormat={this.familyFriendsModal}
                                export
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                <div>

                                 {this.props.role === "admin" && <input style={{cursor:'pointer',marginRight:5}} type="checkbox"  
                                  checked={this.state.showFriendsName}   
                                  onChange={() =>{this.setState({
                                    showFriendsName:!this.state.showFriendsName
                                  })}}  
                                    />}
                              Family/Friends
                                </div>
                              </TableHeaderColumn>
                            ) : null}
                            {
                              this.state.showFriendsName && <TableHeaderColumn
                              width="180px"
                              dataField="friendsCount"
                              dataFormat={this.familyFriendsNameModal}
                              export
                              formatExtraData={this.props}
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            >
                             Family/Friends Name
                            </TableHeaderColumn>
                            }
                            {this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="170px"
                                dataField="participated"
                                dataFormat={this.declaredAttendance}
                                formatExtraData={this.props}
                                export
                                dataSort
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Participation Status
                              </TableHeaderColumn>
                            ) : null}
                            
                            {this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="200px"
                                export
                                dataField="hoursClocked"
                                dataFormat={this.getHours}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Participation Duration
                              </TableHeaderColumn>
                            ) : null}

              {this.state.tableData.status === "Completed" &&
                              this.state.tableData.hasOwnProperty(
                                "registrationsAllowed"
                              ) ? (
                              <TableHeaderColumn
                                width="220px"
                                dataField="hoursClocked"
                                dataFormat={this.familyParticipationTime}
                                formatExtraData={this.props}
                                export
                                dataSort
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Family/Friends Participation Duration
                              </TableHeaderColumn>
                            ) : null}


                            {this.state.tableData.status === "Completed" &&
                              // organisationData
                              //   .eePreferences.enableEmployeeAttendance
                                this.state.employeeAttendanceOverride ? (
                              <TableHeaderColumn
                                width="200px"
                                export
                                dataFormat={this.adminDeclaredAttendance}
                                formatExtraData={this.props}
                                // name='Pending Approval'
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Approval Status
                              </TableHeaderColumn>
                            ) : null}

{
                            this.state.employeeAttendanceOverride
                            // organisationData
                            //   .eePreferences.enableEmployeeAttendance
                               &&
                              this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="200px"
                                export
                                dataField="updatedHoursTime"
                                dataFormat={this.approvedHours}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Approved Hours
                              </TableHeaderColumn>
                            ) : null}

                            {this.state.tableData.status === "Completed" &&
                                 this.state.employeeAttendanceOverride
                              // organisationData
                              //  .eePreferences.enableEmployeeAttendance
                               &&
                              this.props.role === "admin" ? (
                              <TableHeaderColumn width="300px"
                                export={false}
                                dataFormat={this.editButton}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Approver Action
                              </TableHeaderColumn>
                            ) : null}

                             {this.state.tableData.status === "Completed" &&
                                 this.state.employeeAttendanceOverride
                              // organisationData
                              //  .eePreferences.enableEmployeeAttendance
                               ? (
                              <TableHeaderColumn
                                width="200px"
                                export
                                dataField="adminName"
                                dataFormat={this.adminName}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Approver
                              </TableHeaderColumn>
                            ) : null}

                            {this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="160px"
                                export={false}
                                dataFormat={this.viewCertificate}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                View Certificate
                              </TableHeaderColumn>
                            ) : null}

                            {this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="200px"
                                export
                                dataField="Feedback"
                                dataFormat={this.feedback}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Feedback
                              </TableHeaderColumn>
                            ) : null}


                            <TableHeaderColumn
                              width="300px"
                              dataField="email"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Email
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              width="180px"
                              dataField="department"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >Department
                            </TableHeaderColumn>
                            { this.props.role === 'admin' &&
                            <TableHeaderColumn
                              width="180px"
                              dataField="branch_code"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >Branch Code
                            </TableHeaderColumn>
                            }
                            { this.props.role === 'admin' &&
                            <TableHeaderColumn
                              width="180px"
                              dataField="branch_address"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >Branch Address
                            </TableHeaderColumn>
                            }
                            <TableHeaderColumn
                              width="180px"
                              dataField="location"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >Location
                            </TableHeaderColumn>

                            {/* <TableHeaderColumn
                              width="180px"
                              dataField="office"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Office
                            </TableHeaderColumn> */}
                           
                                <TableHeaderColumn
                              width="180px"
                              dataField="city"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              City
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="transportRequired"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Transport required
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="selectedTransportedRoute"
                              export
                              dataFormat={this.selectedTransportRoute}
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Selected Transport Route
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="selectedGroup"
                              export
                              dataFormat={this.selectedBatch}
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Selected Option
                            </TableHeaderColumn>

                            {this.state.tableData.status === "Completed" && this.props.role === "user" ? (
                              <TableHeaderColumn
                                width="200px"
                                export={false}
                                dataFormat={this.employeeEditButton}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                // formatExtraData={this.props}
                              >
                                Edit Duration/Feedback
                              </TableHeaderColumn>
                            ) : null}

                           

                            {(this.state.tableData.status === "Live" || "Ongoing") && this.props.userRole === "admin" ? (
                              <TableHeaderColumn
                                width="140px"
                                export={false}
                                dataFormat={this.deleteParticipant}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Remove Participant
                              </TableHeaderColumn>
                            ) : null}
                          </BootstrapTable>
                        </Row>
                      </div>
                    </div>

                    				
            { this.props.selectedOpportunity.status === "Completed" && this.state.externalParticipantsData.length < 1 && this.state.enableExternalParticipants === true && this.props.userRole === 'admin'  ?
            <><button onClick={this.AddExternalParticipantsTable.bind(this)} className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0">
              Add external participants
                </button></> :(null) }
            {
             this.state.externalParticipantsData.length > 0 && this.props.enableExternalParticipants && (this.props.userRole === 'admin'   ) ? 
             <div className="analytics-container">
             <div className="attendee-analytics">
             <p className="ee-page-external-participant" >External Participants</p>
             {/* <Row> */}
                {/* {this.state.allowExternalParticipants ? (  */}
                {/* <Row> */}
                <div className="attendee-analytics-header">

                               
									<span className="heading"></span>
                                <div className="participated-in-opportunity">
                                <a style={{marginTop:'-2%',marginLeft: '50%',textTransform:"capitalize" }} href='/img/sample_csv.csv' download='sample.csv'  
                  className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0"> Download Sample CSV </a>
                                     </div> 
                               
                              
                               <div className="filters-container">
                                 <div className="form-group mb0">
                                 <button  
                  style={{ marginTop:'-4%',marginLeft: '70%',textTransform:"capitalize" }} 
                  onClick={() =>this.setState({ showUploadCSVModal:true })} 
                  className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0"  >
                  <Upload color='white' style={{ alignSelf: 'center', marginTop:2 }} strokeWidth={3} size={15} /> Upload CSV</button>
                                 </div>
                    
									
                     </div>
                          
                     </div>
                
                {/* <a style={{marginTop:'-2%',marginLeft: '50%',textTransform:"capitalize" }} href='/img/sample_csv.csv' download='sample.csv'  
                  className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0"> Download Sample CSV </a>
                  <button  
                  style={{ marginTop:'-4%',marginLeft: '70%',textTransform:"capitalize" }} 
                  onClick={() =>this.setState({ showUploadCSVModal:true })} 
                  className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0"  >
                  <Upload color='white' style={{ alignSelf: 'center', marginTop:2 }} strokeWidth={3} size={15} /> Upload CSV</button> */}
                {/* </Row> */}
                        <Row>
                          <div className="attendee-analytics-header"></div>
                          <BootstrapTable 
                            data={this.state.externalParticipantsData}
                            hover
                            exportCSV
                            responsive
                            options={externalParticipantsOptions}
                            csvFileName={
                              this.state.selectedOpportunity.title +
                              " - External-Participants - " +
                              String(moment().unix()) +
                              ".csv"
                            }
                          >
                            <TableHeaderColumn isKey dataField="id" hidden> ID
                            </TableHeaderColumn>
                              <TableHeaderColumn
                                width="240px"
                                dataField="name"
                                dataSort
                                export
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Name
                              </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="relationWithOrganisation"
                              export
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            >
                              Relationship
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              width="240px"
                              dataField="hoursClocked"
                              dataFormat={this.getExternalParticipantsHours}
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Number of hours volunteered
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              width="300px"
                              dataField="participantPocEmailId"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >POC
                            </TableHeaderColumn>
                            {this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="160px"
                                export={false}
                                dataFormat={this.viewExternalParticipantsCertificate}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                View Certificate
                              </TableHeaderColumn>
                            ) : null}
                              <TableHeaderColumn
                                width="200px"
                                export={false}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                dataFormat={this.editExternalParticipants}
                                formatExtraData={this.props}
                              >
                                Edit Participant
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                width="200px"
                                export={false}
                                dataFormat={this.removeExternalParticipant}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Remove Participant
                              </TableHeaderColumn>
                                </BootstrapTable>
                                
                                </Row>
                                <button 
                                onClick={this.addExternalParticipants.bind(this)} 
                                style={{marginTop : "2%" }} 
                                className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0">
                                 Add participant
                                 </button>
                                {/* </> */}
                                {/* // ):(null)} */}
                                {/* </Row>  */}
                                
                                </div>
                                </div> : (this.state.allowExternalParticipants  && this.state.externalParticipantsData.length < 1 && (this.props.userRole === 'admin')? (<>  
                                  <p className="ee-page-external-participant" >External Participants</p>
                                 
                                       <button  style={{marginTop:'-6%',marginLeft: '70%',textTransform:"capitalize" }} onClick={() =>this.setState({ showUploadCSVModal:true })} 
                                              className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0"  >
                                        <Upload color='white' style={{ alignSelf: 'center' ,marginTop:2 }} strokeWidth={3} size={15} /> Upload CSV</button>
                                  <Row>
                                <div className="attendee-analytics-header"> </div>
                          <BootstrapTable 
                            data={this.state.externalParticipantsData}
                            hover
                            exportCSV
                            responsive
                            options={externalParticipantsOptions}
                            csvFileName={
                              this.state.selectedOpportunity.title +
                              " - External-Participants - " +
                              String(moment().unix()) +
                              ".csv"
                            }
                          >
                            <TableHeaderColumn isKey dataField="id" hidden> ID
                            </TableHeaderColumn>
                              <TableHeaderColumn
                                width="240px"
                                dataField="name"
                                dataSort
                                export
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}

                              >
                                Name
                              </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="relationWithOrganisation"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              export
                            >
                              Relationship
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              width="240px"
                              dataField="hoursClocked"
                              dataFormat={this.getExternalParticipantsHours}
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Number of hours volunteered
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              width="300px"
                              dataField="participantPocEmailId"
                              export
                              dataSort
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >POC
                            </TableHeaderColumn>
                            {this.state.tableData.status === "Completed" ? (
                              <TableHeaderColumn
                                width="160px"
                                export={false}
                                dataFormat={this.viewExternalParticipantsCertificate}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                View Certificate
                              </TableHeaderColumn>
                            ) : null}
                              <TableHeaderColumn
                                width="200px"
                                export={false}
                                dataFormat={this.editExternalParticipants}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Edit Participant
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                width="200px"
                                export={false}
                                dataFormat={this.removeExternalParticipant}
                                formatExtraData={this.props}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Remove Participant
                              </TableHeaderColumn>
                                
                                </BootstrapTable>
                               
                                </Row>
                                <button 
                                onClick={this.addExternalParticipants.bind(this)}
                                style={{marginTop : "2%" }} 
                                className="btn btn-sq btn-le mr10 btn-declare-attendance form-group mb0">
                                 Add participant
                                 </button>
                                {/* </> */}
                                {/* // ):(null)} */}
                                {/* </Row>  */}
                                
                                </>
                                ):(null) )
                                    }
                  </div>
                </>
              )}
            </Col>
            {this.state.attendeeId !== null &&
              this.state.showAdminDeclareModal ? (

              <AdminDeclareModal
                setParticipants={(data) =>
                  this.setState({ participantsData: data })
                }
                guestList={this.props.friendsList}
                participants={this.state.attendeeData}
                attendeeId={this.state.attendeeId.id}
                index={this.state.editingRowIndex}
                selectedOppurtunityId={this.state.selectedOpportunity.id}
                showDeclareModalVisibility={this.state.showAdminDeclareModal}
                hideAdminDeclareModal={this.hideDeclareModal.bind(this)}
                tableData={this.state.tableData}
                selectedOpportunityData = {this.props.selectedOpportunity}
                // getAllData= {this.getAllData}
                maxVolunteer = {this.props.selectedOpportunity.maxVolunteers}
                totalAttendee={this.state.attendeeVolunteers}
                totalGuest ={this.state.guestVolunteer}
                fetchUpdateOpportunityDetails={()=>this.props.fetchOpportunityDetails()}
                multipleapprove={this.state.multipleAttendenceApprove}
                checkBoxList={this.state.checkBoxList}
                selectAllList={this.state.selectAllList}
                markAttendenceWithDecalredAttendance={this.state.markAttendenceWithDecalredAttendance}
                aftersubmitdeclareModel={this.aftersubmitdeclareModel.bind(this)}

              />
            ) : null}

            {this.state.showFeedbackModal ? (
              <SingleOpportunityFeedbackModal
                selectedOpportunityData={this.props.selectedOpportunity}
                opportunityId={this.state.selectedOpportunity.id}
                showDeclareModalVisibility={this.state.showFeedbackModal}
                index={this.state.editingRowIndex}
                hideDeclareModal={this.hideFeedbackModal.bind(this)}
                participants={this.state.attendeeData}
                data={this.props}
              />
            ) : null}
            {this.state.showEmployeeEditModal ? (
              <SingleOpportunityEmployeeModal
              enableParticipantsForTimeChange ={organisationData.eePreferences.enableParticipantsForTimeChange}
                opportunityId={this.state.selectedOpportunity.id}
                selectedOpportunity={this.state.selectedOpportunity}
                showDeclareModalVisibility={this.state.showEmployeeEditModal}
                selectedOpportunityData={this.props.selectedOpportunity}
                index={this.state.editingRowIndex}
                hideDeclareModal={this.hideEmployeeEditModal.bind(this)}
                participants={this.state.tableData.attendeeDeclaration}
              />
            ) : null}

            {this.state.familyFriendsModal ? (
              <SingleOpportunityFamilyFriendModal
                show={this.state.familyFriendsModal}
                index={this.state.editingRowIndex}
                handleClose={() => this.setState({ familyFriendsModal: false })}
                opportunity={this.state.tableData}
                props={this.props}
                guestList={this.props.friendsList}
              />
            ) : null}

          { this.state.ShowFriendsCertificateModal ? (
            <FamilyCertificateModal 
              show={this.state.ShowFriendsCertificateModal}
              index={this.state.editingRowIndex}
              hideModal = {this.hideFamilyFriendsCertificateModal.bind(this)} 
              guestList={this.props.friendsList}
              opportunity={this.state.tableData}
              />) :null 
              }
             
             { 
             this.state.showExternalParticipantsModal ? (
                 <SingleOpportunityExternalParticipantsModal
                 enableParticipantsForTimeChange={ this.props.enableParticipantsForTimeChange }
                  opportunity={this.props.selectedOpportunity}
                  showDeclareModalVisibility={this.state.showExternalParticipantsModal}
                  hideDeclareModal={this.hideExternalParticipantsModal.bind(this)}
                  getExternalParticipantsList = {this.getExternalParticipantsList}
                 />  ) :null
             }
             {   
             this.state.showExternalParticipantsEditModal ? (
              <SingleOpportunityExternalParticipantsEditModal
              enableParticipantsForTimeChange={ this.props.enableParticipantsForTimeChange }
                  opportunity={this.props.selectedOpportunity}
                  participantsData= {this.state.attendeeId}
                       showDeclareModalVisibility={this.state.showExternalParticipantsEditModal}
                       hideDeclareModal={this.hideExternalParticipantsModal.bind(this)}
                       getExternalParticipantsList = {this.getExternalParticipantsList}                  
                       />  ) :null
               }

             {
               this.state.showOffsetTimeModal ? (
                 <DeclareOffsetHoursModal
                   opportunityId={this.props.selectedOpportunity.id}
                   showDeclareModalVisibility={this.state.showOffsetTimeModal}
                   hideDeclareModal={this.hideOffsetTimeModal.bind(this)}
                   participants={this.props}
                   notifyError={this.props.notifyError}
                   notifySuccess={this.props.notifySuccess}
                   offsetObj={this.props.selectedOpportunity.offsetHours}
                 />
               ) : null
             }
             
               {this.state.showUploadCSVModal ? (
                <UploadCSVModal
                  selectedOpportunity={this.state.selectedOpportunity}
                  showUploadCSVModal={this.state.showUploadCSVModal }
                  hideUploadCSVModal={this.hideUploadCSVModal.bind(this)}
                  getExternalParticipantsList = {this.getExternalParticipantsList} 
                />
              ) : null}
             


            </div>
          )}}
        </OrgDataContext.Consumer>

      );
    } else {
      return null;
    }
  }
}
SingleOpportunityParticipantsList.propTypes = {
  role: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    role: state.RoleReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleOpportunityParticipantsList);

