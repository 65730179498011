import moment from 'moment';
import swal from 'sweetalert2';
import Switch from 'rc-switch';
import { AutoComplete } from 'antd';
import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import Autocomplete from 'react-google-autocomplete';
import { Row, Col, ControlLabel, FormControl, Button, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Multiselect from "react-bootstrap-multiselect";
import $, { data } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";

import 'sweetalert2/dist/sweetalert2.min.css';
import 'flatpickr/dist/themes/material_orange.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import { Check } from 'react-feather';
import { causes } from '../../../constants/Causes';
import configs from '../../../config/urlConfigs';
import { generatePreSignedGetUrl } from '../../../s3PresignedUrls';
import ReactQuill from 'react-quill'


const Option = AutoComplete.Option;

const readCookie = require('../../../cookie.js').readCookie;

const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];
export default class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			goals: [],
			goalsParent: [],
			selectedParentGoal: '',
			activityList: [],
			selectedGoal: '',
			goalId: '',
			activities: [''],
			name: '',
			title: '',
			country: [],
			selectedCountry: 'India',
			states: [],
			selectedState: '',
			area: '',
			street: '',
			cities: [''],
			selectedcity: '',
			pincode: '',
			need: '',
			impact: '',
			dates: [],
			virtualOpportunityUrl: '',
			physicalOpportunity: true,
			startTime: '',
			endTime: '',
			maxVolunteer: '',
			minVolunteer: '',
			location: '',
			image: '',
			allowHistoricalEventCreation: false,
			enableReviewersForValidation: false,
			selectedReviewers: [],
			reviewersList: [],
			facilities: [],
			safetyProtocol: '',
			dos: [''],
			projectLeaderContact: '',
			registrationsAllowed: 0,
			helpfulDocument: null,
			backoutWindow: 0,
			employeeAttendanceOverride: false,
			allowPostEventSignUp:false,
			status: false,
			isIndicativeImage :false,
			addGroupsWithinActivity:false,
			activityGroups:['',''],
			addTransportRoutes:false,
			transportRoutes:['',''],
			addUnitOfMeasurement:false,
			unitOfMeasurement:'',
      addAdmin:false,
      adminList:[],
			searchuser:'',
      userList:[],
      filteredUsersList:[],
      userListloader:true,
      isActive:false,
		}
		this.modules = {
			toolbar: [
			 
			  ["bold", "italic", "underline",],
        [{ 'color': [] }],          
			  [{ list: "ordered" }, { list: "bullet" }],
			 ]
		  };
		this.modules2 = {
			toolbar: [
			 
			  ["bold", "italic", "underline",],
        [{ 'color': [] }],          
			  // [{ list: "ordered" }, { list: "bullet" }],
			 ]
		  };
	  
		  this.formats = [
			"header", "height", "bold", "italic",
			"underline", "strike", "blockquote",
			"list", "color", "bullet", "indent",
			 "align", "size"
		  ];
		
		this.autocomplete = null;
		this.handleChange = this.handleChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleEmployeeAttendanceOverrideChange = this.handleEmployeeAttendanceOverrideChange.bind(this);
		this.handlePostSignUpChange = this.handlePostSignUpChange.bind(this);
		this.handleIndicativeImage = this.handleIndicativeImage.bind(this);
    this.getAllEmployeesList=this.getAllEmployeesList.bind(this)
    this.filterUser=this.filterUser.bind(this)
	}

  getAllEmployeesList() {
    fetch(configs.BACKEND_API_URL + "/all-employees-details", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({ userList: data.allEmployees,
            filteredUsersList: data.allEmployees  ,
            userListloader:false
           });
        }.bind(this)
      );
  }


	componentDidMount() {
     
		let self = this;
		fetch(configs.BACKEND_API_URL + '/get-company-preference', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.status == 'ok') {
				self.setState({
					enableReviewersForValidation: data.data.enableReviewersForValidation === undefined ?
						false : data.data.enableReviewersForValidation,
					allowHistoricalEventCreation: data.data.allowHistoricalEventCreation === undefined ? false :
						data.data.allowHistoricalEventCreation
				});
				return data.data.enableReviewersForValidation === undefined ? false : data.data.enableReviewersForValidation;
			}
		}).then(function (reviewersEnabled) {
			if (reviewersEnabled) {
				fetch(
					configs.BACKEND_API_URL + "/get-employees?role=admin",
					{
						method: "GET",
						headers: {
							Auth: JSON.parse(readCookie("access_token")).access_token,
						},
					}
				)
					.then(function (response) {
						return response.json();
					})
					.then(
						function (data) {
							self.setState({ reviewersList: data.data.map(reviewer => { return { value: JSON.stringify(reviewer), selected: false, label: reviewer.fullName } }) })
						}
					);
			}
		}.bind(this));
		fetch(configs.BACKEND_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			var goalset = [];
			var activityset = [];
			var goalsParent = [];
			for (var i = 0; i < data.otAttributes.length; i++) {
				if (data.otAttributes[i].type === "goal") {
					if (subdomain.toLowerCase() === 'indusind') {
						if (data.otAttributes[i].organization && data.otAttributes[i].organization === 'indusind') {
							!goalsParent.includes(data.otAttributes[i].parent) && goalsParent.push(data.otAttributes[i].parent);
							goalset.push(data.otAttributes[i]);
						}
					} else if (!data.otAttributes[i].hasOwnProperty('organization')) {
						goalset.push(data.otAttributes[i]);
					}
				} else if (data.otAttributes[i].type === "activity") {
					var activityObj = {};
					activityObj.value = data.otAttributes[i]._id;
					activityObj.label = data.otAttributes[i].name;
					activityset.push(activityObj);
				}
			}
			this.setState({ goals: goalset, activityList: activityset, goalsParent }, function () {
				//to fetch list of countries
				fetch(process.env.REACT_APP_COUNTRY_API, {
					method: "GET",
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					var countrieslist = [];
					for (var j = 0; j < data.length; j++) {
						countrieslist.push(data[j]);
					}
					this.setState({ country: countrieslist }, function () {
						if (this.props.match.params.hasOwnProperty('opportunityId')) {
							if (this.props.match.params.opportunityId && this.props.match.params.opportunityId !== 'create') this.edit(this.props.match.params.opportunityId);
							else this.setState({ loaded: true });
						} else this.setState({ loaded: true });
					}.bind(this));
				}.bind(this));
			}.bind(this))
		}.bind(this));
   
	}

  validateForm = () => {
    const { addGroupsWithinActivity, activityGroups, addTransportRoutes, transportRoutes, addUnitOfMeasurement, unitOfMeasurement } = this.state;

    // console.log(addGroupsWithinActivity,addTransportRoutes,addUnitOfMeasurement,activityGroups,transportRoutes,unitOfMeasurement);
    if (!addGroupsWithinActivity  || !addTransportRoutes || !addUnitOfMeasurement ) {
      return false
    }

    if (addGroupsWithinActivity=='Yes' && activityGroups.some(group => group.trim() === '')) {
      return false;
    }
    
    if (addTransportRoutes=='Yes' && transportRoutes.some(route => route.trim() === '')) {
      return false;
    }
    
    if (addUnitOfMeasurement=='Yes' && unitOfMeasurement.trim() === '') {
      return false;
    }

    return true;
  };

    
	handleQuestionChange = (e) => {
		this.setState({ question: e.target.value });
	  };

	  handleOptionChange = (index, event) => {
		const options = [...this.state.options];
		options[index] = event.target.value;
		this.setState({ options });
	  };
	
	  addOption = () => {
		this.setState((prevState) => ({
		  options: [...prevState.options, ''],
		}));
	  };
	
	  removeOption = (index) => {
		this.setState((prevState) => ({
		  options: prevState.options.filter((_, i) => i !== index),
		}));
	  };
	handleCountryChange(name, event) {
		var selected = event.target.value;
		this.setState({ [name]: selected });
	}

	submitform(event) {
		if ((this.state.dates.length || this.props.admin === 'LEadmin') &&
			this.state.title !== '' && this.state.name !== '' && this.state.minVolunteer !== '' &&
			this.state.maxVolunteer !== '' &&
			// (this.state.enableReviewersForValidation ? this.state.selectedReviewers.length : true)&&
			this.state.projectLeaderContact !== '' &&
			this.state.registrationsAllowed !== '' && this.state.image !== '' ) {
			var obj = {
				ngoName: this.state.name,
				title: this.state.title,
				country: this.state.selectedCountry,
				address1: this.state.area,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
				state: this.state.selectedState,
				city: this.state.selectedcity,
				address2: this.state.street,
				pincode: this.state.pincode,
				addressUrl: this.state.location,
				goalId: this.state.goalId,
				description: this.state.need,
				expectedImpact: this.state.impact,
				dates: this.state.dates,
				virtualOpportunityUrl: this.state.virtualOpportunityUrl,
				physicalOpportunity: this.state.physicalOpportunity,
				activities: this.state.activities,
				startTime: this.state.startTime,
				endTime: this.state.endTime,
				minVolunteers: this.state.minVolunteer,
				maxVolunteers: this.state.maxVolunteer,
				image: this.state.image,
				// enableReviewersForValidation: this.state.enableReviewersForValidation,
				selectedReviewers: this.state.selectedReviewers,
				facilities: this.state.facilities,
				safetyProtocol: this.state.safetyProtocol,
				dos: this.state.dos,
				projectLeaderContact: this.state.projectLeaderContact,
				registrationsAllowed: this.state.registrationsAllowed,
				helpfulDocument: this.state.helpfulDocument,
				backoutWindow: this.state.backoutWindow,
				employeeAttendanceOverride: this.state.employeeAttendanceOverride,
				allowPostEventSignUp : this.state.allowPostEventSignUp,
				isIndicativeImage : this.state.isIndicativeImage,
        addGroupsWithinActivity:this.state.addGroupsWithinActivity,
        activityGroups:this.state.activityGroups,
        addTransportRoutes:this.state.addTransportRoutes,
        transportRoutes:this.state.transportRoutes,
        addUnitOfMeasurement:this.state.addUnitOfMeasurement,
        unitOfMeasurement:this.state.unitOfMeasurement,
        adminList:this.state.adminList,
        addAdmin:this.state.addAdmin
			}

			let pastDate = '';
			for (var i = 0; i < this.state.dates.length; i++) {
				var date = moment(this.state.dates[i] + ' ' + this.state.startTime + ':00', 'DD/MM/YYYY HH:mm:ss');
				if (date.isBefore(moment())) {
					pastDate = this.state.dates[i];
				}
			}
			if(this.state.status === 'Live' || this.state.status === 'Completed'){
					swal({
						title: 'Are you sure you want to proceed?',
						html: 'You are going to update the critical details of the project.<br> This will impact the experience of all participants. ',
						type: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Yes, Submit!',
						cancelButtonText: 'Cancel'
					}).then((result) => {
						if (result) {
							if (this.props.match.params.hasOwnProperty('opportunityId') && this.props.match.params.opportunityId) {
								obj['status'] = this.state.status;
								fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.match.params.opportunityId, {
									method: "PUT",
									headers: {
										'Auth': JSON.parse(readCookie('access_token')).access_token,
										'Content-Type': 'application/json',
									},
									body: JSON.stringify(obj),
								}).then(function (response) {
									return response.json();
								}).then(function (data) {
									if (data.status === "ok") {
										swal("Success!", "Your form has been saved for now.", "success").then(() => {
											if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
											else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
										});
									} else if (data.status === "error") {
										swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
									}
								}.bind(this));
							} 
						}
					}).catch(() => { });
			} else if (pastDate) {
				swal({
					title: 'Are you sure you want to submit this?',
					html: 'The opportunity seems to have this (<note>' + pastDate + '</note>) as past date/time.',
					type: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, Submit!',
					cancelButtonText: 'Cancel'
				}).then((result) => {
					if (result) {
						if (this.props.match.params.hasOwnProperty('opportunityId') && this.props.match.params.opportunityId) {
							obj['status'] = 'Pending HR Approval';
							fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.match.params.opportunityId, {
								method: "PUT",
								headers: {
									'Auth': JSON.parse(readCookie('access_token')).access_token,
									'Content-Type': 'application/json',
								},
								body: JSON.stringify(obj),
							}).then(function (response) {
								return response.json();
							}).then(function (data) {
								if (data.status === "ok") {
									swal("Success!", "Your form has been saved for now.", "success");
									if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
									else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
								} else if (data.status === "error") {
									swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
								}
							}.bind(this));
						} else {
							var url = configs.BACKEND_API_URL;
							if (this.props.admin === 'admin') url += '/ee/volunteer-opportunity';
							else if (this.props.admin === 'LEadmin') {
								url += '/volunteer-opportunity';
								obj['date'] = [];
								obj['dateType'] = 'unavailable';
							}
							fetch(url, {
								method: "POST",
								headers: {
									'Auth': JSON.parse(readCookie('access_token')).access_token,
									'Content-Type': 'application/json',
								},
								body: JSON.stringify(obj),
							}).then(function (response) {
								return response.json();
							}).then(function (data) {
								if (data.status === "ok") {
									swal("Success!", "Your form is saved successfully", "success");
									if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
									else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
								} else if (data.status === "error") {
									swal("Oops!", "Have you filled in all the required fields?", "error");
								}
							}.bind(this));
						}
					}
				}).catch(() => { });
			} else {
				if (this.props.match.params.hasOwnProperty('opportunityId') && this.props.match.params.opportunityId) {
					obj['status'] = 'Pending HR Approval';
					fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.match.params.opportunityId, {
						method: "PUT",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(obj),
					}).then(function (response) {
						return response.json();
					}).then(function (data) {
						if (data.status === "ok") {
							swal("Success!", "Your form has been saved for now.", "success");
							if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
							else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
						} else if (data.status === "error") {
							swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
						}
					}.bind(this));
				} else {
					var url = configs.BACKEND_API_URL;
					if (this.props.admin === 'admin') url += '/ee/volunteer-opportunity';
					else if (this.props.admin === 'LEadmin') {
						url += '/volunteer-opportunity';
						obj['date'] = [];
						obj['dateType'] = 'unavailable';
					}
					fetch(url, {
						method: "POST",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(obj),
					}).then(function (response) {
						return response.json();
					}).then(function (data) {
						if (data.status === "ok") {
							swal("Success!", "Your form is saved successfully", "success");
							if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
							else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
						} else if (data.status === "error") {
							swal("Oops!", "Have you filled in all the required fields?", "error");
						}
					}.bind(this));
				}
			}
		} else {
			swal("Oops!", "Have you filled in all the required fields?", "error");
		}
	}

	saveAsDraft(e) {
		if ((this.state.dates.length || this.props.admin === 'LEadmin') && this.state.title !== '') {
			var obj = {
				ngoName: this.state.name,
				title: this.state.title,
				country: this.state.selectedCountry,
				address1: this.state.area,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
				state: this.state.selectedState,
				city: this.state.selectedcity,
				address2: this.state.street,
				pincode: this.state.pincode,
				addressUrl: this.state.location,
				goalId: this.state.goalId,
				description: this.state.need,
				expectedImpact: this.state.impact,
				dates: this.state.dates,
				virtualOpportunityUrl: this.state.virtualOpportunityUrl,
				physicalOpportunity: this.state.physicalOpportunity,
				activities: this.state.activities,
				startTime: this.state.startTime,
				endTime: this.state.endTime,
				minVolunteers: this.state.minVolunteer,
				maxVolunteers: this.state.maxVolunteer,
				image: this.state.image,
				facilities: this.state.facilities,
				safetyProtocol: this.state.safetyProtocol,
				dos: this.state.dos,
				projectLeaderContact: this.state.projectLeaderContact,
				registrationsAllowed: this.state.registrationsAllowed,
				helpfulDocument: this.state.helpfulDocument,
				backoutWindow: this.state.backoutWindow,
				employeeAttendanceOverride: this.state.employeeAttendanceOverride,
				allowPostEventSignUp :this.state.allowPostEventSignUp,
				isIndicativeImage :this.state.isIndicativeImage,
        addGroupsWithinActivity:this.state.addGroupsWithinActivity,
        activityGroups:this.state.activityGroups,
        addTransportRoutes:this.state.addTransportRoutes,
        transportRoutes:this.state.transportRoutes,
        addUnitOfMeasurement:this.state.addUnitOfMeasurement,
        unitOfMeasurement:this.state.unitOfMeasurement,
        adminList:this.state.adminList,
        addAdmin:this.state.addAdmin
			}
			// if( this.state.status === "Draft"){
				obj['status'] = 'Draft';
			// }
			
			if (this.props.match.params.hasOwnProperty('opportunityId') && this.props.match.params.opportunityId) {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.match.params.opportunityId, {
					method: "PUT",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(obj),
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === "ok") {
						swal("Success!", "Your form has been saved for now.", "success");
					} else if (data.status === "error") {
						swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
					}
				});
			} else {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/save-as-draft', {
					method: "POST",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(obj),
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === "ok") {
						swal("Success!", "Your form has been saved for now.", "success").then(function () {
							window.location.pathname = '/volunteer-opportunity/' + data.projectId;
						});
					} else if (data.status === "error") {
						swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
					}
				});
			}
		} else {
			swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
		}
	}

	edit(id) {
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.status === 'ok' && data.data && data.data.type === 'Internal') {
				var loc = document.getElementById('autocompleteBox');
				if (loc !== null) loc.value = data.data.address1;
				let selectedGoalParent = ''
				for (var i = 0; i < this.state.goals.length; i++) {
					if (data.data.goalId === this.state.goals[i]._id) {
						var goalname = this.state.goals[i].name;
						if (subdomain.toLowerCase() === 'indusind') {
							selectedGoalParent = this.state.goals[i].parent
						}
					}
				}
				var dates = [];
				if (data.data.hasOwnProperty('dates')) dates = data.data.dates;
				this.setState(
          {
            name: data.data.ngoName,
            title: data.data.title,
            selectedCountry: data.data.country,
            area: data.data.address1,
            street: data.data.address2,
            selectedcity: data.data.city,
            selectedState: data.data.state,
            pincode: data.data.pincode,
            location: data.data.addressUrl,
            latitude: data.data.latitude,
            longitude: data.data.longitude,
            selectedGoal: goalname,
            goalId: data.data.goalId,
            selectedParentGoal: selectedGoalParent,
            need: data.data.description,
            impact: data.data.expectedImpact,
            activities: data.data.activities,
            dates: dates,
            virtualOpportunityUrl: data.data.virtualOpportunityUrl,
            physicalOpportunity: data.data.physicalOpportunity,
            startTime: data.data.startTime,
            endTime: data.data.endTime,
            maxVolunteer: data.data.maxVolunteers,
            minVolunteer: data.data.minVolunteers,
            image: data.data.image,
            facilities: data.data.facilities ? data.data.facilities : [],
            safetyProtocol:
              data.data.safetyProtocol && data.data.safetyProtocol,
            dos: data.data.dos ? data.data.dos : [""],
            projectLeaderContact:
              data.data.projectLeaderContact && data.data.projectLeaderContact,
            registrationsAllowed:
              data.data.registrationsAllowed && data.data.registrationsAllowed,
            helpfulDocument:
              data.data.helpfulDocument && data.data.helpfulDocument,
            backoutWindow: data.data.backoutWindow
              ? data.data.backoutWindow
              : 0,
            employeeAttendanceOverride: data.data.employeeAttendanceOverride
              ? data.data.employeeAttendanceOverride
              : false,
            status: data.data.status,
            allowPostEventSignUp: data.data.allowPostEventSignUp
              ? data.data.allowPostEventSignUp
              : false,
            isIndicativeImage: data.data.isIndicativeImage
              ? data.data.isIndicativeImage
              : false,
            addGroupsWithinActivity: data.data.hasOwnProperty('addGroupsWithinActivity')?data.data.addGroupsWithinActivity:false,
            activityGroups: data.data.activityGroups?data.data.activityGroups:['',''],
            addTransportRoutes: data.data.hasOwnProperty('addTransportRoutes')?data.data.addTransportRoutes:false,
            transportRoutes: data.data.transportRoutes?data.data.transportRoutes:['',''],
            unitOfMeasurement: data.data.unitOfMeasurement?data.data.unitOfMeasurement:'',
            addUnitOfMeasurement: data.data.hasOwnProperty('addUnitOfMeasurement')?data.data.addUnitOfMeasurement:false,
            adminList:data.data.adminList?data.data.adminList:[],
            addAdmin:data.data.hasOwnProperty('addAdmin')?data.data.addAdmin:false,
          },
          function () {
            this.setState({ loaded: true });
          }.bind(this)
        );
			} else if (data.status === 'ok' && data.data.type !== 'Internal') {
				if (data.data.customUrl) window.location.pathname = '/opportunity/' + data.data.customUrl;
				else window.location.pathname = '/opportunity/' + data.data.id;
			} else window.location.pathname = '/volunteer-management';
      // this.getAllEmployeesList()
		}.bind(this));
	}

	addrow() {
		var addrow = [];
		addrow = this.state.activities;
		addrow.push('');
		this.setState({ activities: addrow });
	}

	addDosrow() {
		var addDosRow = [];
		addDosRow = this.state.dos;
		addDosRow.push('');
		this.setState({ dos: addDosRow });
	}

  filterUser(e){
    let lowerCaseQuery=e.target.value
    
    const filteredUsers = this.state.userList?.filter(user => {
      return (
        (user.fullName && user.fullName.toLowerCase().includes(lowerCaseQuery)) ||  // Match 'name' field
        (user.employeeId && user.employeeId.toLowerCase().includes(lowerCaseQuery))           // Match 'id' field
      );
    });
    if(lowerCaseQuery==''){
      this.setState({
        filteredUsersList: this.state.userList,
        searchuser: e.target.value,
      })
    }
    else{

      this.setState({
        filteredUsersList: filteredUsers,
        searchuser: e.target.value,
      })
    }
  }

  toggleElementById( choosenUser) {
    // Find the index of the element with the matching id
    console.log(choosenUser);
    let list=this.state.adminList
    const index = list.findIndex(item => item.employeeId === choosenUser?.employeeId);
  
    if (index === -1) {
      // If the element is not found, add it to the list
      list.push({
        employeeId: choosenUser?.employeeId,
        fullName: choosenUser?.fullName,
        _id:choosenUser?._id
      }); // You can add more properties if needed
    } else {
      // If the element is found, remove it from the list
      list.splice(index, 1);
    }
    console.log(list);
    this.setState({
      adminList:list
    })
  }


	deleterow(id, e) {
		var delrow = [];
		delrow = this.state.activities;
		delrow.splice(id, 1)
		this.setState({ activities: delrow })
	}

	deleteDosrow(id, e) {
		var delDoRow = [];
		delDoRow = this.state.dos;
		delDoRow.splice(id, 1);
		this.setState({ dos: delDoRow })
	}

	convertImage = (file, event) => {
		let data = new FormData();

		const blobURL = URL.createObjectURL(file);
		fetch(blobURL)
			.then((res) => res.blob())
			.then((blob) => heic2any({ blob }))
			.then((conversionResult) => {
				let image = conversionResult;
				data.append("image", image);
				fetch(configs.BACKEND_API_URL + '/uploadImage', {
					method: "POST",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token,
					},
					body: data
				}).then((data) => data.json())
					.then(function (data) {
						if (data && data.url) {
							this.setState({ image: data.url })
						}
					}.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); event.target.value = null; });
			});
			}

      handleFocus = () => {
        this.setState({ isActive: true });
      };
    
      handleBlur = () => {
        this.setState({ isActive: false });
      };

	onImageChange(event, key) {
		
		
		let data = new FormData();
		if(key === 'image'){
			if (/\.(jpe?g|png|heic|heif)$/i.test(event.target.files[0].name) === false) {
				this.notifyError("Only png, heic/heif and jpeg formats are accepted.");
			}
			else {
				if ((event.target.files[0].type
				).includes('hei')) {
					// image =
					this.convertImage(event.target.files[0], event);
				} else {

					data.append("image", event.target.files[0]);
					fetch(configs.BACKEND_API_URL + '/uploadImage', {
						method: "POST",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token,
						},
						body: data
					}).then((data) => data.json())
						.then(function (data) {
							if (data && data.url) {
								this.setState({ image: data.url })
							}
							// key === 'document' ? this.setState({ helpfulDocument: data.url }) : 
						}.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); event.target.value = null; });
				}
			
			}  
			    }
			 else {
				if(event && event.target && event.target.files && event.target.files[0].type === 'application/pdf' ){ 
					 data.append("image", event.target.files[0]);
					 fetch(configs.BACKEND_API_URL + '/uploadImage', {
						method: "POST",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token,
						},
						body: data
					}).then((data) => data.json())
						 .then(function (data) {
							 if (data && data.url) {
								 this.setState({ helpfulDocument: data.url })  
							}
							// this.setState({ image: data.url })
						 }.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); event.target.value = null; });
					}
				  else {
					this.notifyError("Only pdf format is accepted");
					}
				}
			
		// let data = new FormData();
		// data.append("image", event.target.files[0]);
		
	
	}

	deleteImage() {
		this.setState({ image: '' })
	}
	WORD_LIMITS = {
		name: 20,
		title: 20,
		street: 20,
		need: 2000,
		impact: 2000,
		safetyProtocol: 1000,
		DOSANDDONTS: 50,
	};
handleEmployeeAttendanceOverrideChange(e) {
	this.setState({employeeAttendanceOverride: !this.state.employeeAttendanceOverride || false });
}
handlePostSignUpChange(e){
			this.setState({allowPostEventSignUp : !this.state.allowPostEventSignUp || false});
}

handleIndicativeImage(e) {
          this.setState({isIndicativeImage : !this.state.isIndicativeImage || false});
}

	handleChange(name, e) {
		// if (name in this.WORD_LIMITS) {
		// 	if (e.target && e.target.value.split(" ").length > this.WORD_LIMITS[name]) {
		// 		this.notifyError(`Input can't be more than ${this.WORD_LIMITS[name]} words!`);
		// 		e.target.value = e.target.value.split(" ").slice(0, this.WORD_LIMITS[name]).join(" ");
		// 	} else if (e && !e.target  && (e || '').split(" ").length > this.WORD_LIMITS[name]) {
		// 		this.notifyError(`Input can't be more than ${this.WORD_LIMITS[name]} words!`);
		// 		e = (e || '').split(" ").slice(0, this.WORD_LIMITS[name]).join(" ");
		// 	} 
		// }
		if(name === "startTime" || name === "endTime"){
			this.setState({ [name]: e.target.value });
		}
		else if(name=="impact"||name=="need"){
			this.setState({ [name]: e});
			console.log(e,name);
		}
		else if(e.target){
			// let commentRegex = new RegExp(`^([A-Za-z]+)(?:[A-Za-z0-9 _]*)$`)
		//    let commentRegex = new RegExp("^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?]*$")
		// let commentRegex = new RegExp	('^[a-zA-Z0-9!@#$&?!*%()\\-`.+,/\\s\w"]+(?: [a-zA-Z0-9!@#$&?!*%()\\-`.+,/\\s\w"]+)*$')
			if (
				// commentRegex.test(e.target.value) === true 
				// ||
			true
			) {
			
			if (e.target) {
				if (e.target.value.split(" ").length > this.WORD_LIMITS[name]) {
					this.notifyError(`Input can't be more than ${this.WORD_LIMITS[name]} words!`);
					e.target.value = e.target.value.split(" ").slice(0, this.WORD_LIMITS[name]).join(" ");
				}
				this.setState({ [name]: e.target.value });
			}
			
			else {
				if (e && (e).split(" ").length > this.WORD_LIMITS[name]) {
					this.notifyError(`Input can't be more than ${this.WORD_LIMITS[name]} words!`);
					e = (e).split(" ").slice(0, this.WORD_LIMITS[name]).join(" ");
				} 
				this.setState({ [name]: e });
			}
		}
		else {
			this.notifyError("Only letters, numbers and special characters are allowed")
		}
		}
		else if(name === "physicalOpportunity") {
			this.setState({ physicalOpportunity: !this.state.physicalOpportunity });
		}
		
	
	}

	handleGoalChange(name, e) {
		var val = e.target.value;
		for (var i = 0; i < this.state.goals.length; i++) {
			if (val === this.state.goals[i].name) {
				var id = this.state.goals[i]._id;
				this.setState({ goalId: id, [name]: e.target.value });
			}
		}
	}

	onSelect(index, value, x) {
		if (value) {
			var activities = this.state.activities;
			activities[index] = { 'id': value, 'name': x.props.children }
			this.setState({ activities });
		}
	}
	notifyError = (text) => toast.error(text, {autoClose: 3000});
	notifySuccess = (text) => toast.success(text, { autoClose: 3000 });
	onAddDosAndDonts(index, value, x) {
        var dos = this.state.dos;
				dos[index] = { 'id': index, 'name': value}
				this.setState({ dos });
		// let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
		// if(commentRegex.test(value.target.value) === true || true){
		// 	const wordLength = value.target.value || '' ? value.target.value.split(" ").length : 0;
		// 	if (wordLength<=this.WORD_LIMITS['DOSANDDONTS'] && value) {
		// 		var dos = this.state.dos;
		// 		dos[index] = { 'id': index, 'name': value.target.value , bold:dos[index]?.bold||false, italic:dos[index]?.italic||false }
		// 		this.setState({ dos });
		// 	}
		// 	else if (wordLength > this.WORD_LIMITS['DOSANDDONTS']) {
		// 		this.notifyError(`Input can't be more than ${this.WORD_LIMITS['DOSANDDONTS']} words!`)
		// 		var dos = this.state.dos;
		// 		dos[index] = { 'id': index, 'name': value.target.value.split(" ").slice(0, this.WORD_LIMITS['DOSANDDONTS']).join(" ") , bold:dos[index]?.bold||false, italic:dos[index]?.italic||false}
		// 		this.setState({ dos });
		// 	}
		// }
		// else {
		// 	this.notifyError("Only letters, numbers and special characters are allowed")
		// }
		
	}

	boldItalicAddDos(index,bold,italic){
		console.log(index,bold,italic);
		// Ensure the element at the given index is an object, not an empty string
		if (this.state.dos[index] === "") {
			// Replace the empty string with an object
			this.setState(prevState => {
			  const updatedDos = [...prevState.dos];
			  updatedDos[index] = { id: index, name: "" };  // Modify with your desired default values
			  return { dos: updatedDos };
			});
		  }
		if (bold) {
			if (this.state.dos[index].hasOwnProperty('bold')) {
			  // Toggle the 'bold' key value if it exists
			  this.setState(prevState => {
				const updatedDos = [...prevState.dos];
				updatedDos[index].bold = !updatedDos[index].bold;
				return { dos: updatedDos };
			  });
			} else {
			  // Add the 'bold' key with value 'true' if it doesn't exist
			  this.setState(prevState => {
				const updatedDos = [...prevState.dos];
				updatedDos[index].bold = true;
				return { dos: updatedDos };
			  });
			}
		  }
		  
		  // Check if 'italic' is true
		  if (italic) {
			if (this.state.dos[index].hasOwnProperty('italic')) {
			  // Toggle the 'italic' key value if it exists
			  this.setState(prevState => {
				const updatedDos = [...prevState.dos];
				updatedDos[index].italic = !updatedDos[index].italic;
				return { dos: updatedDos };
			  });
			} else {
			  // Add the 'italic' key with value 'true' if it doesn't exist
			  this.setState(prevState => {
				const updatedDos = [...prevState.dos];
				updatedDos[index].italic = true;
				return { dos: updatedDos };
			  });
			}
		  }
	}



	handleAddFacilities(e) {
		var facilities = [];
		facilities = this.state.facilities;
		facilities.includes(e) ? facilities.splice(facilities.indexOf(e), 1) : facilities.push(e);
		this.setState({ facilities })
	}

	handleSearch(index, value) {
		for (var i = 0; i < this.state.activityList.length; i++) {
			var option = this.state.activityList[i];
			if (option.label.toLowerCase().match(value.toLowerCase()) !== null) {
				break;
			}
		}
		var newActivity = {};
		newActivity['id'] = '';
		newActivity['name'] = value;

		var activities = this.state.activities;
		activities[index] = newActivity;
		this.setState({ activities });
	}

	handleDateChange(dates) {
		var convertedDates = [];
		for (var i = 0; i < dates.length; i++) {
			if (dates[i] !== '') convertedDates.push(moment(dates[i], 'YYYY-MM-DD').format('DD/MM/YYYY'));
		}
		this.setState({ dates: convertedDates });
	}
	updateReviewerPreferences(response) {
		this.setState({ selectedReviewers: $(response).parent().val() });
	}
	render() {
		const tooltip = (
			<Tooltip id="tooltip">
				<p>your location URL</p>
			</Tooltip>
		);


		const options = this.state.activityList.map(function (activity, index) {
			return (
				<Option key={index} value={activity.value}>{activity.label}</Option>
			)
		});
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)

		if (this.state.loaded || true) {
			return (
        <div className="ee-page-container volunteer-form">
          <ToastContainer
            position="bottom-center"
            autoClose={50000}
            hideProgressBar={true}
            closeOnClick={false}
            newestOnTop={false}
            pauseOnHover={true}
          />
          <form className="ee-form-layout">
            <div className="ee-form">
              <Row className="form-row">
                <Col md={12} xs={12} className="form-header">
                  <p className="ee-page-title">
                    Volunteer Opportunity Creation Form
                  </p>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={4} xs={12} className="form-ngo-name">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Name of the NGO <span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8} xs={12}>
                  <div className="form-input">
                    <FormControl
                      key="ngoname"
                      onChange={this.handleChange.bind(this, "name")}
                      type="text"
                      value={this.state.name}
                      placeholder="NGO Name"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={4} className="form-project-title">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Title of the project <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8}>
                  <div className="form-input">
                    <FormControl
                      type="text"
                      onChange={this.handleChange.bind(this, "title")}
                      value={this.state.title}
                      placeholder="Project Title"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={4} className="form-project-title">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Type of opportunity <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8}>
                  <div className="form-input">
                    <span style={{ marginRight: 15 }}>Virtual</span>
                    <Switch
                      onChange={this.handleChange.bind(
                        this,
                        "physicalOpportunity"
                      )}
                      checked={this.state.physicalOpportunity}
                    />
                    <span style={{ marginLeft: 15 }}>On-Ground</span>
                  </div>
                </Col>
              </Row>
              {this.state.physicalOpportunity ? (
                <Row className="form-row">
                  <Col md={4} className="form-address">
                    <div className="form-label">
                      <ControlLabel className="label padding0 m0">
                        Address of the project site{" "}
                        <span className="star"> * </span>
                      </ControlLabel>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-address-details">
                      <Row className="form-row">
                        <Col md={12}>
                          <FormControl
                            componentClass="select"
                            value={this.state.selectedCountry}
                            className="select-tab"
                            id="sel1"
                            onChange={this.handleCountryChange.bind(
                              this,
                              "selectedCountry"
                            )}
                          >
                            <option value="">Select your country</option>
                            {this.state.country.map(function (
                              countries,
                              index
                            ) {
                              return (
                                <option key={index} value={countries.name}>
                                  {countries.name}
                                </option>
                              );
                            })}
                          </FormControl>
                        </Col>
                      </Row>

                      <Row className="form-row">
                        <Col md={12}>
                          <Autocomplete
                            className="form-address-autocomplete"
                            id="autocompleteBox"
                            ref="autocomplete"
                            onPlaceSelected={(place) => {
                              if (
                                typeof place.address_components != "undefined"
                              ) {
                                var input =
                                  document.getElementById(
                                    "autocompleteBox"
                                  ).value;
                                this.setState({ area: input });

                                for (
                                  var i = 0;
                                  i < place.address_components.length;
                                  i++
                                ) {
                                  var addressType =
                                    place.address_components[i].types[0];
                                  var val =
                                    place.address_components[i]["long_name"];
                                  if (addressType === "locality") {
                                    this.setState({ selectedcity: val });
                                  } else if (
                                    addressType ===
                                    "administrative_area_level_1"
                                  ) {
                                    this.setState({ selectedState: val });
                                  } else if (addressType === "postal_code") {
                                    this.setState({ pincode: val });
                                  }
                                }
                              }

                              let newUrl = `https://www.google.com/maps/search/?api=1&query=${place.formatted_address}&query_place_id=${place.place_id}`;
                              var url = newUrl;

                              // var url = place.url;
                              this.setState({
                                location: url,
                                latitude: place.geometry.location.lat(),
                                longitude: place.geometry.location.lng(),
                              });
                            }}
                            types={[]}
                            defaultValue={this.state.area}
                          />
                        </Col>
                      </Row>

                      <Row className="form-row">
                        <Col md={12}>
                          <FormControl
                            type="text"
                            onChange={this.handleChange.bind(this, "street")}
                            value={this.state.street}
                            placeholder="Street/House/Flat no."
                          ></FormControl>
                        </Col>
                      </Row>

                      <Row className="form-row">
                        <Col md={12}>
                          <FormControl
                            type="text"
                            value={this.state.selectedcity}
                            className="select-tab"
                            onChange={this.handleChange.bind(
                              this,
                              "selectedcity"
                            )}
                            placeholder="City"
                          ></FormControl>
                        </Col>
                      </Row>

                      <Row className="form-row">
                        <Col sm={6} md={6}>
                          <FormControl
                            type="text"
                            value={this.state.selectedState}
                            className="select-tab"
                            onChange={this.handleChange.bind(
                              this,
                              "selectedState"
                            )}
                            placeholder="State"
                          ></FormControl>
                        </Col>

                        <Col sm={6} md={6}>
                          <FormControl
                            type="text"
                            onChange={this.handleChange.bind(this, "pincode")}
                            value={this.state.pincode}
                            placeholder="PinCode"
                          ></FormControl>
                        </Col>
                      </Row>

                      <Row className="form-row">
                        <Col md={12}>
                          <OverlayTrigger placement="left" overlay={tooltip}>
                            <InputGroup>
                              <InputGroup.Button>
                                <Button className="locate">
                                  <i className="fa fa-map-marker"></i>{" "}
                                </Button>
                              </InputGroup.Button>
                              <FormControl
                                type="text"
                                onChange={this.handleChange.bind(
                                  this,
                                  "location"
                                )}
                                value={this.state.location}
                                placeholder="Google maps location URL"
                              ></FormControl>
                            </InputGroup>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="form-row">
                  <Col md={4} className="form-virtual-opportunity-url">
                    <div className="form-label">
                      <ControlLabel className="label padding0 m0">
                        Link to join the opportunity
                      </ControlLabel>
                    </div>
                  </Col>

                  <Col md={8}>
                    <div className="form-input">
                      <FormControl
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "virtualOpportunityUrl"
                        )}
                        value={this.state.virtualOpportunityUrl}
                        placeholder="Virtual Opportunity Url"
                      ></FormControl>
                    </div>
                  </Col>
                </Row>
              )}

              <Row className="form-row">
                <Col md={4} className="form-goal">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      {" "}
                      Goal<span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                </Col>
                <Col md={8}>
                  {subdomain.toLowerCase() === "indusind" ? (
                    <>
                      <FormControl
                        componentClass="select"
                        value={this.state.selectedParentGoal}
                        className="select-tab"
                        onChange={(e) =>
                          this.setState({
                            selectedParentGoal: e.target.value,
                            selectedGoal: "",
                          })
                        }
                      >
                        <option value=""> Select a goal </option>
                        {this.state.goalsParent.map((goallist, index) => (
                          <option key={index} value={goallist}>
                            {goallist}
                          </option>
                        ))}
                      </FormControl>
                      {this.state.selectedParentGoal && (
                        <div className="ngoGoal" style={{ marginTop: 10 }}>
                          <FormControl
                            componentClass="select"
                            value={this.state.selectedGoal}
                            className="select-tab"
                            onChange={this.handleGoalChange.bind(
                              this,
                              "selectedGoal"
                            )}
                          >
                            <option value=""> Select a cause </option>
                            {this.state.goals
                              .filter(
                                (x) =>
                                  x.parent === this.state.selectedParentGoal
                              )
                              .map(function (goallist, index) {
                                return (
                                  <option key={index} value={goallist.name}>
                                    {goallist.name}
                                  </option>
                                );
                              })}
                          </FormControl>
                        </div>
                      )}{" "}
                    </>
                  ) : (
                    <div className="ngoGoal">
                      <FormControl
                        componentClass="select"
                        value={this.state.selectedGoal}
                        className="select-tab"
                        onChange={this.handleGoalChange.bind(
                          this,
                          "selectedGoal"
                        )}
                      >
                        <option value=""> Select a goal </option>
                        {this.state.goals.map(function (goallist, index) {
                          return (
                            <option key={index} value={goallist.name}>
                              {goallist.name}
                            </option>
                          );
                        })}
                      </FormControl>
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="form-row mb-20screen">
                <Col md={12} xs={12} className="form-need-and-plan">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      A description of the need and plan of the project{" "}
                      <span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                  <div className="form-input">
                    {/* <FormControl componentClass="textarea" className="need-and-plan" value={this.state.need} onChange={this.handleChange.bind(this, 'need')} placeholder=""></FormControl> */}
                    <ReactQuill
                      value={this.state.need}
                      theme="snow"
                      modules={this.modules}
                      formats={this.formats}
                      placeholder="write your content ...."
                      onChange={(e) => {
                        this.handleChange("need", e);
                      }}
                      style={{ height: "150px", marginBottom: "50px" }}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="form-row mb-20screen">
                <Col md={12} className="form-expected-impact">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      What is the expected impact of the volunteering project?{" "}
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                  <div className="form-input">
                    {/* <FormControl className="impact" componentClass="textarea" value={this.state.impact} onChange={this.handleChange.bind(this, 'impact')} placeholder=""></FormControl> */}
                    <ReactQuill
                      value={this.state.impact}
                      theme="snow"
                      modules={this.modules}
                      formats={this.formats}
                      placeholder="write your content ...."
                      onChange={(e) => {
                        this.handleChange("impact", e);
                        console.log(e);
                      }}
                      style={{ height: "150px", marginBottom: "50px" }}
                    />
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  margin: "15px 0px",
                }}
              >
                <Row className="form-row" style={{}}>
                  <Col sm={12} md={12} className="form-activities-undertaken">
                    <div className="form-label">
                      <ControlLabel className="label padding0 m0">
                        Enlist activities undertaken as part of this project
                        <span className="star"> * </span>
                      </ControlLabel>
                    </div>
                  </Col>
                </Row>
                <Col
                  md={2}
                  className="activity-button"
                  style={{ padding: "0px", textAlign: "right" }}
                >
                  {/* <Button className="btn-sq btn-le" style={{ width: '100%' }} onClick={this.addrow.bind(this)} bsSize="sm"> <i className="fa fa-plus"></i> Add </Button> */}
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.addrow.bind(this)}
                  >
                    <i className="fa fa-plus"></i> Add
                  </button>
                </Col>
              </div>

              <Row>
                {this.state.activities.map((newrow, index) => {
                  return (
                    <Col key={index} md={12} style={{ marginTop: 5 }}>
                      <Row className="ml5 mr5" style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1, marginRight: 10 }}>
                          <div className="form-input">
                            <AutoComplete
                              dataSource={options}
                              className="autosearch"
                              placeholder="Enter Activity"
                              onSelect={this.onSelect.bind(this, index)}
                              value={this.state.activities[index].name}
                              onSearch={this.handleSearch.bind(this, index)}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          </div>
                        </div>
                        <div className="text-right">
                          <Button
                            className="delete-row padding0"
                            style={{ height: 32 }}
                            onClick={this.deleterow.bind(this, index)}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  );
                })}
              </Row>

              

              <div style={{ display:'flex',
                flexDirection:'column',
                marginTop: 40 }}>
                <Col style={{
                  margin:'0 0 20px 0'
                }} md={4} className="form-goal">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      {" "}
                      Would you like to define groups within an activity, such
                      as batches or activity sets?
                      <span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                </Col>
                <Col md={8}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col md={4}>
                      {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="toilet" checked={this.state.facilities.includes('toilet')} onChange={() => this.handleAddFacilities('toilet')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="toilet">Toilet</label>
												</div> */}
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addGroupsWithinActivity == "Yes"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addGroupsWithinActivity: "Yes",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="toilet"
                        >
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="drinkingWater" checked={this.state.facilities.includes('drinkingWater')} onChange={() => this.handleAddFacilities('drinkingWater')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="drinkingWater">Drinking Water</label>
												</div> */}
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addGroupsWithinActivity == "No"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addGroupsWithinActivity: "No",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="drinkingWater"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>

              {this.state.addGroupsWithinActivity == "Yes" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
					  marginLeft:15
                    }}
                  >
                    <label class="form-check-label">Options:</label>
                    <Col md={2}>
                      <Row>
                        <div
                          className="activity-button"
                          style={{
                            right: 0,
                            textAlign: "right",
                            width: "100%",
                          }}
                        >
                          {/* <Button className="btn-sq btn-le" style={{ width: '100%' }} onClick={this.addDosrow.bind(this)} bsSize="sm"> <i className="fa fa-plus"></i> Add </Button> */}
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={(e) => {
                              this.setState((prevState) => ({
                                activityGroups: [
                                  ...prevState.activityGroups,
                                  "",
                                ],
                              }));
                            }}
                          >
                            Add Option
                          </button>
                        </div>
                      </Row>
                    </Col>
                  </div>

                  {this.state.activityGroups.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
						marginLeft:15
                      }}
                    >
                      <input
                        style={{
                          padding: "5px 10px",
                          borderRadius: "3px",
                          border: "1px solid #CBCBCB",
                          margin:index !==0 ? "5px 10px 5px 0px" : "5px 25px 5px 0px",
                          height: 32,
						  width:'100%'
                        }}
                        type="text"
                        value={option}
                        onChange={(event) => {
                          const options = [...this.state.activityGroups];
                          options[index] = event.target.value;
                          this.setState({ activityGroups:options });
                        }}
                        placeholder={`Option ${index + 1}`}
                      />
                       { index !==0 && <div className="text-right">
                          <Button
                            className="delete-row padding0"
                          type="button"
                          onClick={() =>
                            this.setState((prevState) => ({
                              activityGroups: prevState.activityGroups.filter(
                                (_, i) => i !== index
                              ),
                            }))
                          }
                        >
                           <i className="fa fa-trash"></i>
                        </Button>
                      </div>}
                    </div>
                  ))}
                </>
              )}
             <div style={{ display:'flex',
                flexDirection:'column',
                marginTop: 40 }}>
                <Col md={4} className="form-goal">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      {" "}
                      Do you need to set up transport routes for this activity?
                      <span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                </Col>
                <Col md={8}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col md={4}>
                      {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="toilet" checked={this.state.facilities.includes('toilet')} onChange={() => this.handleAddFacilities('toilet')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="toilet">Toilet</label>
												</div> */}
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addTransportRoutes == "Yes"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addTransportRoutes: "Yes",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="toilet"
                        >
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="drinkingWater" checked={this.state.facilities.includes('drinkingWater')} onChange={() => this.handleAddFacilities('drinkingWater')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="drinkingWater">Drinking Water</label>
												</div> */}
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addTransportRoutes == "No"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addTransportRoutes: "No",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="drinkingWater"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>

              {this.state.addTransportRoutes == "Yes" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
					  marginLeft:15
                    }}
                  >
                    <label class="form-check-label">Options:</label>
                    <Col md={2}>
                      <Row>
                        <div
                          className="activity-button"
                          style={{
                            right: 0,
                            textAlign: "right",
                            width: "100%",
                          }}
                        >
                          {/* <Button className="btn-sq btn-le" style={{ width: '100%' }} onClick={this.addDosrow.bind(this)} bsSize="sm"> <i className="fa fa-plus"></i> Add </Button> */}
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={(e) => {
								this.setState((prevState) => ({
								  transportRoutes: [
									...prevState.transportRoutes,
									"",
								  ],
								}));
							  }}
                          >
                            Add Option
                          </button>
                        </div>
                      </Row>
                    </Col>
                  </div>

                  {this.state.transportRoutes.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
						marginLeft:15
                      }}
                    >
                      <input
                        style={{
                          padding: "5px 10px",
                          borderRadius: "3px",
                          border: "1px solid #CBCBCB",
                          margin:index !==0 ? "5px 10px 5px 0px" : "5px 25px 5px 0px",
                          height: 32,

						              width:'100%'
                        }}
                        type="text"
                        value={option}
						onChange={(event) => {
							const options = [...this.state.transportRoutes];
							options[index] = event.target.value;
							this.setState({ transportRoutes:options });
						  }}
                        placeholder={`Option ${index + 1}`}
                      />
                       { index !==0 && <div className="text-right">
                          <Button
                            className="delete-row padding0"
                          type="button"
                          onClick={() =>
                            this.setState((prevState) => ({
                              transportRoutes: prevState.transportRoutes.filter(
                                (_, i) => i !== index
                              ),
                            }))
                          }
                        >
                           <i className="fa fa-trash"></i>
                        </Button>
                      </div>}
                    </div>
                  ))}
                </>
              )}

              <div style={{ display:'flex',
                flexDirection:'column',
                marginTop: 40 }}>
                <Col md={4} className="form-goal">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      {" "}
					  Would you like to add a Unit of Measurement for the opportunity creation?
                      <span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                </Col>
                <Col md={8}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col md={4}>
                      
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addUnitOfMeasurement == "Yes"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addUnitOfMeasurement: "Yes",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="toilet"
                        >
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                     
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addUnitOfMeasurement == "No"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addUnitOfMeasurement: "No",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="drinkingWater"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>

              {this.state.addUnitOfMeasurement == "Yes" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    margin: "10px 20px 0 20px",
                  }}
                >
                  <label class="form-check-label">Question:</label>
                  <input
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      borderRadius: "3px",
                      border: "1px solid #CBCBCB",
                      margin: "0px 10px 10px 0px",
                      height: 32,
					  width:'100%'
                    }}
                    type="text"
                    value={this.state.unitOfMeasurement}
                    onChange={(e) =>
                      this.setState({
                        unitOfMeasurement: e.target.value,
                      })
                    }
                    placeholder="Enter your question"
                  />
                </div>
              )}
            {/* <div style={{ display:'flex',
                flexDirection:'column',
                marginTop: 40 }}>
                <Col md={4} className="form-goal">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      {" "}
					  Would you like to add Users as a Admin for this Opportunity?
                      <span className="star"> * </span>{" "}
                    </ControlLabel>
                  </div>
                </Col>
                <Col md={8}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col md={4}>
                      
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addAdmin == "Yes"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addAdmin: "Yes",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="toilet"
                        >
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      
                      <div class="form-check checkbox-row">
                        <a
                          className={
                            this.state.addAdmin == "No"
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          onClick={() =>
                            this.setState({
                              addAdmin: "No",
                            })
                          }
                        >
                          <Check
                            color="white"
                            style={{ alignSelf: "center" }}
                            strokeWidth={3}
                            size={12}
                          />
                        </a>
                        <label
                          class="form-check-label"
                          style={{ margin: 10 }}
                          for="drinkingWater"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div> */}

              {/* {
                this.state.addAdmin && 
                <>
                <div style={{display:'flex',flexDirection:'column'}}>
                <input
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      borderRadius: "3px",
                      border: "1px solid #CBCBCB",
                      margin: "10px 10px 10px 10px",
                      height: 32,
					            width:'100%'
                    }}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    type="text"
                    value={this.state.searchuser}
                    onChange={(e) =>
                      this.filterUser(e)
                    }
                    placeholder="Enter the name or ibl id of user"
                  />
                  <div style={{display:'flex',flexDirection:'column',overflow:'scroll',height:200,marginLeft:10}}>
                  {this.state.filteredUsersList.length>0 ? this.state.filteredUsersList?.map((item,idx)=>{
                    let list=this.state.adminList
                    const index = list.findIndex(items => items.employeeId === item?.employeeId);
                    return(
                      <div style={{borderRadius: 5,
                        border:' 0.5px solid gray',
                        padding: '5px 10px',
                        cursor:'pointer',
                        background:index!==-1?'#84181F':'none'
                          
                      }} key={idx}
                        onClick={e=>this.toggleElementById(item)}
                        >
                        <p style={{color:index!==-1?'white':'none'}}>{item?.fullName + '  ' + item.employeeId}</p>
                        </div>
                    )
                  })
                : this.state.userListloader ?
                <div> Loading ... </div>  
                : null        
                }
                  </div>
                </div>
                </>
              } */}
              {/* <div style={{display:'flex',flexDirection:'row',marginLeft:10,flexWrap:'wrap',gap:8}}>
                {this.state.adminList  && this.state.adminList?.map(item=>(
                  <div style={{borderRadius: 15,
                    background:'#84181F',
                    padding: '5px 10px',
                    cursor:'pointer',  
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    gap:5
                  }} 
                  onClick={e=>this.toggleElementById(item)}
                  >
                    <p style={{color:'white'}}>{item?.fullName + '  ' + item.employeeId}</p>
                    <i className="fa fa-times"></i>
                    </div>
                ))}
              </div> */}
             
            

              {this.state.physicalOpportunity && (
                <>
                  <Row style={{ marginTop: 30 }}>
                    <Col md={4} className="form-goal">
                      <div className="form-label">
                        <ControlLabel className="label padding0 m0">
                          {" "}
                          Available facilities <span className="star">
                            {" "}
                            *{" "}
                          </span>{" "}
                        </ControlLabel>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col md={4}>
                          {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="toilet" checked={this.state.facilities.includes('toilet')} onChange={() => this.handleAddFacilities('toilet')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="toilet">Toilet</label>
												</div> */}
                          <div class="form-group form-check checkbox-row">
                            <a
                              className={
                                this.state.facilities.includes("toilet")
                                  ? "checkbox-active"
                                  : "checkbox-inactive"
                              }
                              onClick={() => this.handleAddFacilities("toilet")}
                            >
                              <Check
                                color="white"
                                style={{ alignSelf: "center" }}
                                strokeWidth={3}
                                size={12}
                              />
                            </a>
                            <label
                              class="form-check-label"
                              style={{ margin: 10 }}
                              for="toilet"
                            >
                              Toilet
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="drinkingWater" checked={this.state.facilities.includes('drinkingWater')} onChange={() => this.handleAddFacilities('drinkingWater')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="drinkingWater">Drinking Water</label>
												</div> */}
                          <div class="form-group form-check checkbox-row">
                            <a
                              className={
                                this.state.facilities.includes("drinkingWater")
                                  ? "checkbox-active"
                                  : "checkbox-inactive"
                              }
                              onClick={() =>
                                this.handleAddFacilities("drinkingWater")
                              }
                            >
                              <Check
                                color="white"
                                style={{ alignSelf: "center" }}
                                strokeWidth={3}
                                size={12}
                              />
                            </a>
                            <label
                              class="form-check-label"
                              style={{ margin: 10 }}
                              for="drinkingWater"
                            >
                              Drinking Water
                            </label>
                          </div>
                        </Col>

                        <Col md={4}>
                          {/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="mealOrSnack" checked={this.state.facilities.includes('mealOrSnack')} onChange={() => this.handleAddFacilities('mealOrSnack')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="mealOrSnack">Meal/Snacks</label>
												</div> */}
                          <div class="form-group form-check checkbox-row">
                            <a
                              className={
                                this.state.facilities.includes("mealOrSnack")
                                  ? "checkbox-active"
                                  : "checkbox-inactive"
                              }
                              onClick={() =>
                                this.handleAddFacilities("mealOrSnack")
                              }
                            >
                              <Check
                                color="white"
                                style={{ alignSelf: "center" }}
                                strokeWidth={3}
                                size={12}
                              />
                            </a>
                            <label
                              class="form-check-label"
                              style={{ margin: 10 }}
                              for="mealOrSnack"
                            >
                              Meal/Snacks
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4} className="form-goal">
                      <div className="form-label">
                        <ControlLabel className="label padding0 m0">
                          {" "}
                          Covid-safety protocol{" "}
                        </ControlLabel>
                      </div>
                    </Col>
                    <Col md={8}>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Covid-safety protocol"
                        value={this.state.safetyProtocol}
                        onChange={this.handleChange.bind(
                          this,
                          "safetyProtocol"
                        )}
                      ></textarea>
                    </Col>
                  </Row>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                  marginTop: "30px",
                }}
              >
                {/* <Row 
							style={{ marginTop: 30 }} 
							> */}
                <Col md={10} className="form-goal">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Important Instructions
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={2}>
                  <Row>
                    <div
                      className="activity-button"
                      style={{ right: 0, textAlign: "right", width: "100%" }}
                    >
                      {/* <Button className="btn-sq btn-le" style={{ width: '100%' }} onClick={this.addDosrow.bind(this)} bsSize="sm"> <i className="fa fa-plus"></i> Add </Button> */}
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={this.addDosrow.bind(this)}
                      >
                        <i className="fa fa-plus"></i> Add
                      </button>
                    </div>
                  </Row>
                </Col>

                {/* </Row> */}
              </div>
              {this.state.dos.map((newrow, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 20,
                    }}
                  >
                    <div className="form-input" style={{ width: "98%" }}>
                    <ReactQuill
                        value={this.state.dos[index].name}
                        theme="snow"
                      modules={this.modules2}
                      formats={this.formats}
                      placeholder="Important Instructions"
                      onChange={this.onAddDosAndDonts.bind(this, index)}

                      style={{ height: "150px", marginBottom: "50px" }}
                    />
                      {/* <FormControl
                        type="text"
                        onChange={this.onAddDosAndDonts.bind(this, index)}
                        value={this.state.dos[index].name}
                        placeholder="Important Instructions"
                      ></FormControl> */}
                    </div>
                    <div className="text-right">
                      <Button
                        className="delete-row padding0"
                        style={{ height: 32, alignSelf: "flex-start" }}
                        onClick={this.deleteDosrow.bind(this, index)}
                      >
                        {" "}
                        <i className="fa fa-trash"></i>
                      </Button>
                    </div>
					{/* <div style={{margin:'0px 5px',display:'flex',flexDirection:'row',alignItems:'center'}}>
						<input style={{cursor:'pointer',marginTop:0}} type="checkbox" className="" 
						checked={ this.state.dos[index]?.bold}  
						onChange={this.boldItalicAddDos.bind(this, index,'true',false)}  
							/>
						<span style={{fontSize:14,fontWeight:'600',whiteSpace:'nowrap',marginLeft:10}}>Bold</span>
					</div>
					<div style={{margin:'0px 5px',display:'flex',flexDirection:'row',alignItems:'center'}}>
						<input style={{cursor:'pointer',marginTop:0}} type="checkbox" className="" 
						checked={ this.state.dos[index]?.italic}  
						onChange={this.boldItalicAddDos.bind(this, index,false,true)}  
							/>
						<span style={{fontSize:14,fontWeight:'600',whiteSpace:'nowrap',marginLeft:10}}>Italic</span>

					</div> */}
                  </div>
                );
              })}

              <Row className="form-row">
                <Col md={4} xs={12} className="form-ngo-name">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Emergency/ Project leader contact{" "}
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8} xs={12}>
                  <div className="form-input">
                    <FormControl
                      key="projectLeaderContact"
                      onChange={this.handleChange.bind(
                        this,
                        "projectLeaderContact"
                      )}
                      type="text"
                      value={this.state.projectLeaderContact}
                      placeholder="Emergency Project leader contact"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={4} xs={12} className="form-ngo-name">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Maximum Family/Friends allowed per volunteer
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8} xs={12}>
                  <div className="form-input">
                    <FormControl
                      key="registrationsAllowed"
                      onChange={(e) =>
                        e.target.value > -1 &&
                        e.target.value <= 100 &&
                        this.setState({ registrationsAllowed: e.target.value })
                      }
                      type="number"
                      value={this.state.registrationsAllowed}
                      placeholder="Max. Family/Friends Registration allowed"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={4} xs={12} className="form-ngo-name">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Backout window in hours (before the event begins){" "}
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8} xs={12}>
                  <div className="form-input">
                    <FormControl
                      key="backoutWindow"
                      onChange={(e) =>
                        e.target.value > -1 &&
                        e.target.value < 251 &&
                        this.setState({ backoutWindow: e.target.value })
                      }
                      type="number"
                      value={this.state.backoutWindow}
                      placeholder="Backout window in hours (before the event begins)"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={4} xs={12} className="form-ngo-name">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Any helpful document{" "}
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={8} xs={12}>
                  <Row className="form-row image-buttons">
                    {this.state.helpfulDocument ? (
                      <>
                        <Col md={6}>
                          <note>
                            <a
                              href={generatePreSignedGetUrl(
                                this.state.helpfulDocument
                              )}
                              target="_blank"
                            >
                              Document Link
                            </a>
                            {/* {this.state.helpfulDocument && typeof this.state.helpfulDocument === 'string' ?
												this.state.helpfulDocument.split("/")[this.state.helpfulDocument.split("/").length - 1] : ''} */}
                          </note>
                          {/* <note>Document Selected</note> */}
                          {/* <note>{this.state.helpfulDocument && typeof this.state.helpfulDocument === 'string' ? this.state.helpfulDocument.split("/")[this.state.helpfulDocument.split("/").length-1] : ''}</note> */}
                        </Col>{" "}
                        <Col md={6}>
                          <Button
                            className="delete-image"
                            onClick={() =>
                              this.setState({ helpfulDocument: null })
                            }
                          >
                            <i className="fa fa-trash"></i> Delete
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <Col md={6}>
                        <FormControl
                          type="file"
                          accept=".pdf"
                          pattern=".+\.pdf$"
                          onChange={(e) => this.onImageChange(e, "document")}
                        ></FormControl>
                      </Col>
                    )}
                  </Row>
                </Col>
                {/* <Col md={8} xs={12}>
									<Row className="form-row image-buttons">

										{this.state.helpfulDocument && this.state.helpfulDocument.type === 'application/pdf' ? <Col md={6}>
											<button className="btn btn-primary" onClick={() => this.setState({ helpfulDocument: null })}><i className="fa fa-trash"></i> Delete</button>
										</Col> : <Col md={6}>
											<FormControl type="file" accept=".pdf" onChange={(e) => this.onImageChange(e, "document")}></FormControl>
										</Col>}
									</Row>
								</Col> */}
              </Row>

              <Row className="form-row">
                <Col md={12} className="form-volunteering-date mb10">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      Enlist following details of volunteering
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={12}>
                  {this.props.admin === "admin" ? (
                    <Col md={4} className="mb15 padding0">
                      <Col md={12}>
                        <div className="form-label">
                          <label className="control-label label padding0 m0">
                            Date(s) of volunteering
                            <span className="star"> * </span>
                          </label>
                        </div>
                      </Col>

                      <Col md={12}>
                        {this.state.status === "Completed" ||
                        this.state.status === "Live" ? (
                          <div className="form-input date-picker-container">
                            <FormControl
                              placeholder={this.state.dates.map((date) => date)}
                              disabled
                            />
                          </div>
                        ) : (
                          <div className="form-input date-picker-container">
                            <Flatpickr
                              options={{
                                allowInput: false,
                                mode: "multiple",
                                dateFormat: "d/m/Y",
                                altFormat: "d/m/Y",
                                altInputClass: "form-control",
                                altInput: true,
                                minDate: this.state.allowHistoricalEventCreation
                                  ? null
                                  : "today",
                              }}
                              value={this.state.dates}
                              onChange={(dates) => this.handleDateChange(dates)}
                            />
                          </div>
                        )}
                        {/* <div className="form-input date-picker-container">
													<Flatpickr options={{ allowInput: false, mode: 'multiple', dateFormat: 'd/m/Y', altFormat: 'd/m/Y', altInputClass: 'form-control', altInput: true, minDate: this.state.allowHistoricalEventCreation ? null : "today" }} value={this.state.dates} onChange={dates => this.handleDateChange(dates)} />
												</div> */}
                      </Col>
                    </Col>
                  ) : null}

                  <Col md={4} className="mb15 padding0">
                    <Col md={12}>
                      <div className="form-label">
                        <label className="control-label label padding0 m0">
                          Suggested start time of project
                          <span className="star"> * </span>
                        </label>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="form-input">
                        <FormControl
                          type="time"
                          value={this.state.startTime}
                          onChange={this.handleChange.bind(this, "startTime")}
                          placeholder=""
                        ></FormControl>
                      </div>
                    </Col>
                  </Col>

                  <Col md={4} className="mb15 padding0">
                    <Col md={12}>
                      <div className="form-label">
                        <label className="control-label label padding0 m0">
                          Suggested end time of project{" "}
                          <span className="star"> * </span>
                        </label>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="form-input">
                        <FormControl
                          type="time"
                          value={this.state.endTime}
                          onChange={this.handleChange.bind(this, "endTime")}
                          placeholder=""
                        ></FormControl>
                      </div>
                    </Col>
                  </Col>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={8} className="form-min-volunteer">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      How many volunteers (min) can be accommodated at the
                      event? (including family & friends){" "}
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="form-input">
                    <FormControl
                      type="number"
                      value={this.state.minVolunteer}
                      onChange={this.handleChange.bind(this, "minVolunteer")}
                      placeholder="Min Volunteers"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={8} className="form-max-volunteer">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      How many volunteers (max) can be accommodated at the
                      event? (including family & friends){" "}
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="form-input">
                    <FormControl
                      type="number"
                      value={this.state.maxVolunteer}
                      onChange={this.handleChange.bind(this, "maxVolunteer")}
                      placeholder="Max Volunteers"
                    ></FormControl>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={8} className="form-max-volunteer">
                  <div className="form-group form-check checkbox-row">
                    <a
                      className={
                        this.state.employeeAttendanceOverride
                          ? "checkbox-active"
                          : "checkbox-inactive"
                      }
                      onClick={this.handleEmployeeAttendanceOverrideChange.bind(
                        this
                      )}
                      onChange={this.handleEmployeeAttendanceOverrideChange.bind(
                        this
                      )}
                    >
                      <Check
                        color="white"
                        style={{ alignSelf: "center" }}
                        strokeWidth={3}
                        size={12}
                      />
                    </a>
                    <div className="form-label">
                      <ControlLabel className="label padding0 m2">
                        {" "}
                        This event requires an attendance approver.
                      </ControlLabel>
                    </div>
                    {/* <label class="form-check-label" style={{ margin: 10 }} for="employeeAttendanceOverride"> */}
                    {/* Toilet</label> */}
                    {/* Enable employee attendance validation/override */}
                    {/* </label> */}
                  </div>
                </Col>

                {/* <Col md={4}>
									<div className="form-input">
										<FormControl type="number" value={this.state.maxVolunteer} onChange={this.handleChange.bind(this, 'maxVolunteer')} placeholder="Max Volunteers"></FormControl>
									</div>
								</Col> */}
              </Row>
              {/* <label class="form-check-label" style={{ margin: 10 }} for="enable-EmployeeAttendance">
							</label> */}
              {/* {this.state.enableReviewersForValidation && <Row className="form-row">
								<Col md={3} className="form-max-volunteer">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Select Reviewers<span className="star"> * </span></ControlLabel>
									</div></Col>
								<Col md={9}>	<Multiselect
									id="reviewers-multiselect"
									className="form-control inline"
									value={this.state.selectedReviewers}
									onChange={this.updateReviewerPreferences.bind(
										this,
										//index
									)}
									buttonWidth={"100%"}
									data={this.state.reviewersList}
									enableCaseInsensitiveFiltering
									multiple
								/>
								</Col>
							</Row>} */}

              <Row className="form-row">
                <Col md={8} className="form-max-volunteer">
                  <div className="form-group form-check checkbox-row">
                    <a
                      className={
                        this.state.allowPostEventSignUp
                          ? "checkbox-active"
                          : "checkbox-inactive"
                      }
                      onClick={this.handlePostSignUpChange.bind(this)}
                      onChange={this.handleEmployeeAttendanceOverrideChange.bind(
                        this
                      )}
                    >
                      <Check
                        color="white"
                        style={{ alignSelf: "center" }}
                        strokeWidth={3}
                        size={12}
                      />
                    </a>
                    <div className="form-label">
                      <ControlLabel className="label padding0 m2">
                        Allow post-event signup.
                      </ControlLabel>
                    </div>
                    {/* <label class="form-check-label" style={{ margin: 10 }} for="employeeAttendanceOverride"> */}
                    {/* Toilet</label> */}
                    {/* Enable employee attendance validation/override */}
                    {/* </label> */}
                  </div>
                </Col>

                {/* <Col md={4}>
									<div className="form-input">
										<FormControl type="number" value={this.state.maxVolunteer} onChange={this.handleChange.bind(this, 'maxVolunteer')} placeholder="Max Volunteers"></FormControl>
									</div>
								</Col> */}
              </Row>

              <Row className="form-row">
                <Col md={12} className="form-upload">
                  <div className="form-label">
                    <ControlLabel className="label padding0 m0">
                      A representative cover image of the event{" "}
                      <span className="star"> * </span>
                    </ControlLabel>
                  </div>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={8} className="image-field">
                  <div className="input-image-file">
                    <img
                      onError={(e) => {
                        e.target.src =
                          "https://assets.letsendorse.com/sahaj/project-placeholder.jpg";
                      }}
                      src={generatePreSignedGetUrl(this.state.image)}
                      className="myimage"
                      alt="choose an image"
                    />
                  </div>
                </Col>

                <Col md={4} className="image-buttons">
                  <Row className="form-row">
                    <FormControl
                      type="file"
                      accept=".png, .jpg, .jpeg, .heic, .heif"
                      onChange={(e) => this.onImageChange(e, "image")}
                    ></FormControl>
                  </Row>
                  <Row className="form-row">
                    {/* <Button className="delete-image" onClick={this.deleteImage.bind(this)}><i className="fa fa-trash"></i> Delete</Button> */}
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={this.deleteImage.bind(this)}
                    >
                      <i className="fa fa-trash"></i> Delete
                    </button>
                  </Row>
                </Col>
              </Row>
              <Row className="form-row">
                <Col md={8} className="form-max-volunteer">
                  <div className="form-group form-check checkbox-row">
                    <a
                      className={
                        this.state.isIndicativeImage
                          ? "checkbox-active"
                          : "checkbox-inactive"
                      }
                      onClick={this.handleIndicativeImage.bind(this)}
                      onChange={this.handleIndicativeImage.bind(this)}
                    >
                      <Check
                        color="white"
                        style={{ alignSelf: "center" }}
                        strokeWidth={3}
                        size={12}
                      />
                    </a>
                    <div className="form-label">
                      <ControlLabel className="label padding0 m2">
                        Check if this is an indicative image of the event
                      </ControlLabel>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* <Row className="form-row">
								<div className="bottom-buttons">
									{this.props.admin === 'admin' ? (
										<Col md={6} sm={6} className="form-save-as-draft">
											<Button className="submitbtn" onClick={this.saveAsDraft.bind(this)}><i className="fa fa-download"></i> Save as Draft</Button>

										</Col>
									) : (null)}
									<Col md={6} sm={6} className="form-submit">
										<Button className="submitbtn" onClick={this.submitform.bind(this)}> <i className="fa fa-paper-plane"></i> Submit</Button>

									</Col>
								</div>
							</Row> */}

              {/* {console.log(this.state.dates,this.state.dates.length ,
                      this.props.admin === "LEadmin",
                    this.state.title !== "" ,
                    this.state.name !== "" ,
                    this.state.minVolunteer !== "" ,
                    this.state.maxVolunteer !== "" ,
                    this.state.projectLeaderContact !== "" ,
                    this.state.registrationsAllowed !== "" ,
                    this.validateForm())} */}

              <Row className="form-row">
                {this.props.admin === "admin" ? (
                  <div className="bottom-buttons" style={{ marginTop: 20 }}>
                    {this.state.status === "Live" ||
                    this.state.status === "Completed" ? null : this.state
                        .status === "Draft" || !this.state.status ? (
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={this.saveAsDraft.bind(this)}
                      >
                        <i className="fa fa-download"></i> Save as Draft
                      </button>
                    ) : null}
                    {(this.state.dates.length ||
                      this.props.admin === "LEadmin") &&
                    this.state.title !== "" &&
                    this.state.name !== "" &&
                    this.state.minVolunteer !== "" &&
                    this.state.maxVolunteer !== "" &&
                    this.state.projectLeaderContact !== "" &&
                    this.state.registrationsAllowed !== "" &&
                    this.validateForm()
                     &&
                    this.state.image !== "" 
                    ? (
                      this.state.status === "Live" ||
                      this.state.status === "Completed" ||
                      this.state.status === "Expired" ? (
                        <button
                          type="button"
                          class="btn btn-primary"
                          style={{ marginLeft: 15 }}
                          onClick={this.submitform.bind(this)}
                        >
                          <i className="fa fa-paper-plane"></i> Submit
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-primary"
                          style={{ marginLeft: 15 }}
                          onClick={this.submitform.bind(this)}
                        >
                          <i className="fa fa-paper-plane"></i> Submit for
                          Approval
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        class="btn  btn-save-disabled"
                        disabled
                        style={{ marginLeft: 15 }}
                      >
                        <i className="fa fa-paper-plane"></i> Submit for
                        Approval
                      </button>
                    )}
                  </div>
                ) : null}
              </Row>
            </div>
          </form>
        </div>
      );
		} else return null;
	}
}